/**
 * Base.
 * Any resets or base inherited styles
 * forms
 */

/**
 * baseline responsive img style - wont oversize
 */
img{
	max-width:100%;
  height:auto;
}
.admin-menu .slide-nav{
	padding-top: 45px;
}

/**
 * over rides to get round admin menu stuff
 */
.navbar-administration{
	#page-header{
		top: 39px;
	}
}
#page-header.nav-up {
  top: -60px !important;
}

/**
 * Forms
 */
.form-email{
	.form-control
}
body.modal-open{
	margin-right: 0;
}
a {
  color: @sib-primary;
}
.btn.btn-primary,
.btn.btn-secondary {
  border-radius: 0;

}
.btn-primary {
  background-color: @sib-primary;
  border: none;
  &:hover {
    background-color: @sib-secondary;
  }
}
.btn-secondary {
  background-color: @sib-secondary;
  color: #fff;
  border: none;
  &:hover {
    background-color: darken(@sib-secondary, 10%);
    color: #fff;
  }
}
.btn-large {
  width: 300px;
  max-width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-padding {
  padding: 0 !important;
}
.download-link {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    background-image: url(../../images/icons/download.svg);
    height: 20px;
    width: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    right: 15px;
  }
}

.form-type-radio {
  input {
    display: none;
  }
  label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    margin-bottom: 5px;
    &:before {
      content: '';
      width: 18px;
      height: 18px;
      left: 0;
      background: #d8d8d8;
      border: 1px solid #b8b8b8;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
    }
    &:after {
      content: '';
      width: 0px;
      height: 0px;
      left: 4px;
      background: @sib-info;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.1s all;
    }
  }
  input:checked + label:after{
    width: 10px;
    height: 10px;
  }
}