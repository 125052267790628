.node-type-book {
  h1.page-header {
    font-size: 36px;
  }
}
.sidebar .block.block-book {
  margin-top: 30px;
  ul.menu {
    padding: 0;
  }
  ul.menu ul li a {
    padding: 8px 20px;
  }
  > ul.menu {
    border-left: 10px solid lighten(@text-color, 60%);
    padding: 0 0 0 5px;
    > li {
      padding: 0 !important;
    }
    a {
      padding: 8px 40px 8px 10px;
      &:hover {
        background-color: #def2f6 !important;
      }
    }
  }
  > ul > li {
    border-top: 1px solid lighten(@text-color, 60%);
  }
  > ul > li:last-child {
    border-bottom: 1px solid lighten(@text-color, 60%);
  }
  li.expanded,
  li.collapsed {
    > a {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-60%);
        transition: 0.2s all;
        height: 15px;
        width: 15px;
        background-image: url("../../images/icons/chevron.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  li.collapsed > a:after {
    transform: translateY(-50%) rotate(180deg);
  }

  @media @tablet {
    margin-top: 0;
  }
}
.book-navigation {
  background: lighten(@sib-blue, 20%);
  color: #000;
  padding: 20px;
  display: flex;
  flex-direction: column-reverse;
  margin-top: 15px;

  .label {
    font-size: 14px;
    color: #000;
    margin-bottom: 20px;
    display: block;
    text-align: left;
  }
  a {
    color: #000;
    font-size: 14px;
    text-decoration: underline;
    display: flex;
    img {
      height: 12px;
      margin-right: 8px;
      margin-top: 4px;
    }
  }

  .next-section {
    text-align: center;
    margin-bottom: 20px;
  }
  .link {
    .button-cta-solid();
    a:link {
      padding: 0 20px;
      line-height: 2.5em;
    }
  }

  @media @tablet {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .next-section {
      align-items: center;
      display: flex;
    }

    .label {
      display: inline-block;
      padding: .3em 1em;
      position: relative;
      top: 2px;
    }
    .next-section,
    .label {
      margin-bottom: 0;
    }
  }
}
