/**
 * Theme.
 * Colors, fonts, borders, backgrounds etc.
 */

.logo img{
 width: 190px;
}
::selection {
  background: rgba(128, 45, 119, 0.50);
}
::-moz-selection{
  background: rgba(128, 45, 119, 0.50);
}
body {
  color: #000;
  font-size: @font-size-base;
}
h1,.h1,h2,.h2,h3,.h3{
  font-family: @font-family-headers;
}
h1,.h1{
  font-size: @font-size-h1-mobile;
  color: @header-primary;
  font-weight: bold;
  margin: 0;
  @media @tablet{
    font-size: @font-size-h1;
  }
  &.page-header{
    margin-top: 0 !important;
  }
}
h2,.h2{
  font-size: @font-size-h2-mobile;
  color: @header-primary;
  font-weight: bold;
  @media @tablet{
    font-size: @font-size-h2;
  }
}
h3,.h3{
  font-size: @font-size-h3-mobile;
  color: @header-secondary;
  font-weight: normal;
  @media @tablet{
    font-size: @font-size-h3;
  }
  a{
    color: @header-secondary;
    &:hover{
      color: @header-secondary;
    }
  }
}
h4,.h4{
  font-size: @font-size-h4-mobile;
  @media @tablet{
    font-size: @font-size-h4;
  }
  a{
    color: @text-color;
    &:hover{
      color: @text-color;
    }
  }
}

table{
  .table;
  .table-bordered;
}

p{
  line-height: 1.5;
  &.larger{
    font-weight: 700;
    font-size: @font-size-large-mobile;
    @media @tablet{
      font-size: @font-size-large;
    }
  }
  &.smaller{
    font-size: @font-size-small-mobile;
    @media @tablet{
      font-size: @font-size-small;
    }
  }
  span.highlight{
    background-color: rgba(46, 188, 236, 0.2);
    display: inline-block;
  }
}

ol{
  li{
    margin-bottom: 10px;
  }
  &.h3 {
    h4 {
      color: @text-color;
    }
    p, ul, ol {
      font-size: 16px;
      color: @text-color;
      font-family: museo-sans, sans-serif;
    }
    a,
    a:hover {
      color: @brand-primary;
    }
  }
}

/* BLOCK QUOTE */
blockquote {
  border: 0;
  padding-left: 2em;

  & p {
    display: inline;
    font-size: @font-size-h4;
    font-style: italic;
    font-weight: 500;
    line-height: 1.42;
    position: relative;
    quotes: "\201C""\201D""\2018""\2019";

    &:before,
    &:after {
      position: absolute;
      color: @brand-primary;
      font-size: @font-size-h2;
    }

    &:before {
      content: open-quote;
      left: -0.6em;
      top: -0.4em;
    }

    &:after {
      content: close-quote;
      //right: -0.8em;
      bottom: -1em;
    }
  }

  footer {
    color: @brand-primary;
  }
}

.breadcrumb{
  background-color: inherit;
  font-size: 12px;
  padding: 25px 0px;
  margin-bottom: 0;
  max-width: none;
  a{
    color: #941c81;
  }
}
.breadcrumb > li + li:before{
  color: @text-color;
  font-weight: bold;
}
.breadcrumb > .active{
  color: @text-color;
}
/**
 * FORM
 */

.btn{
  border-radius: 6px;
  padding: 10px 28px 8px;
  margin-bottom: 30px;
  margin-right: 15px;
  &.btn-default{
    background-color: #2aa3d4;
    color: #fff;
    font-size: 14px;
    border-color: #2aa3d4;
    &:hover{
      background-color: #2fb4ea;
      border-color: #2fb4ea;
      color: #fff;
    }
    &:active,&:focus{
      background-color: #11a2cd;
      border-color: #11a2cd;
      color: #fff;
    }
  }
  &.btn-details{
    background-color: #933c86;
    color: #fff;
    font-size: 14px;
    border-color: #933c86;
    &:hover{
      background-color: #b249a3;
      border-color: #b249a3;
      color: #fff;
    }
    &:active,&:focus{
      background-color: #803475;
      border-color: #803475;
      color: #fff;
    }
  }
  &.btn-default-empty{
    background-color: transparent;
    color: #11addb;
    font-size: 14px;
    border-color: #11addb;
    box-sizing: border-box;
    &:hover{
      background-color: transparent;
      box-shadow: 0px 0px 0px 1px #11addb inset;
      color: #11addb;
    }
    &:active,&:focus{
      background-color: #11addb;
      border-color: #11addb;
      color: #fff;
    }
  }
  &.btn-equity{
    background-color: #2aa3d4;
    color: #fff;
    font-size: 14px;
    border-color: #2aa3d4;
    &:hover{
      background-color: #2fb4ea;
      border-color: #2fb4ea;
      color: #fff;
    }
    &:active,&:focus{
      background-color: #11a2cd;
      border-color: #11a2cd;
      color: #fff;
    }
  }
  &.btn-borrow{
    background-color: #802d77;
    color: #fff;
    font-size: 14px;
    border-color: #802d77;
    &:hover{
      background-color: #a13896;
      border-color: #a13896;
      color: #fff;
    }
    &:active,&:focus{
      background-color: #74276c;
      border-color: #74276c;
      color: #fff;
    }
  }
  &.btn-other{
    background-color: #cf4960;
    color: #fff;
    font-size: 14px;
    border-color: #cf4960;
    &:hover{
      background-color: #e5516a;
      border-color: #e5516a;
      color: #fff;
    }
    &:active,&:focus{
      background-color: #c04459;
      border-color: #c04459;
      color: #fff;
    }
  }
  &:active,&:focus{
    box-shadow: 0 0 0;
  }
}
input, select, textarea, .form-control{
  border: solid 2.5px #979797;
  border-radius: 3px;
  margin-bottom: 10px;
  @media @tablet{
    min-width: 300px;
  }
  background-color: #fff;
  min-height: 32px;
  &:focus{
    border-color: #2dbded;
  }
}
select{
  height: 32px;
}
textarea{
  min-height: 146px;
}
.checkbox label,
.radio label{
  //line-height: 40px;
}
input[type="radio"], input[type="radio"], input[type="checkbox"], input[type="checkbox"]{
  min-width: 5px;
  margin-right: 10px;
  min-height: 0 !important;
}

// Lists in ckeditor fields.
.field-type-text-with-summary {
  ul {
    padding: 0;
  }
  ul li {
    padding-left: 20px;
    list-style: none;
    position: relative;
    margin-bottom: 1em;
    &:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      top: 8px;
      left: 0;
      background: @sib-primary;
      border-radius: 50%;
    }
    ul {
      margin-top: 0.5em;
    }
    li {
      margin-bottom: 0.5em;
    }
    li:before {
      background: transparent;
      border: 1px solid @sib-primary;
    }
  }
}
ol {
  padding-left: 20px;
}

.pale-background {
  padding: 10px 40px;
  background: #F1EAF2;
  display: inline-block;
}
.blue-panel {
  background: #d1edf6;
  padding: 20px;
}