.card-hero-image {
  color: white;
  background-position: center center;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;

  &.highlight h3 {
    background-color: white;
    color: #802D77;
    display: inline-block;
    padding: 13px;
    font-weight: bold !important;
    letter-spacing: -0.72px;
    margin: 2em 0 0.4em 0;
  }

  .card-wrapper {
    padding: 40px 0 130px;
    background-color: transparent !important;
    @media @tablet {
      text-align: left;
      padding-bottom: 130px;
    }
  }
  p {
    color: #000;
    font-weight: bold !important;
    font-size: 20px !important;
  }

  .field-name-field-link {
    .button-cta-trans();
    position: relative;
    @media @normal {
      margin: 0 0 20px 0;
    }
    &:hover {
      background-color: #3A9CB6;
    }
  }

  .field-name-field-body {
    font-size: 0.7em;
    position: relative;
    margin: 0 15px 20px;
    @media @normal {
      width: 75%;
      font-size: 2em;
      margin: 0 0 20px 0;
    }
  }

  .field-name-field-background-media {
    position: absolute;
    top: calc(~'(100vh - 135px - 1080px) / 2');
    left: calc(~'(100vw - 1920px) / 2');
    z-index: -1;
  }

  &.is-image {
    .field-name-field-body {
      h3 {
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        @media @normal {
          font-size: 33px;
        }
      }
    }
  }

  &.is-video {
    height: calc(~'100vh - 135px');
    overflow: hidden;

    display: flex;
    align-items: center;
    .card-wrapper {
      padding: 0 0;
      width: 100%;
      display: block;
    }

    &::before {
      background: linear-gradient(270deg, rgba(0,0,0,0.3) 100%, rgba(0,0,0,0.3) 100%, #000000 0%);
      background-size: cover;
      position: absolute;
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
    }

    span.arrow-down {
      background: transparent url(../../images/Down.png) no-repeat center center;
      visibility: visible;
      position: absolute;
      bottom: 30px;
      left: 0;
      height: 35px;
      width: 50px;
      right: 0;
      z-index: 9999999;
      margin: auto;
      cursor: pointer;
    }
  }
}

// Card hero on front page.
.front .card-hero-image {
  min-height: 400px;
  background-size: 80%;
  h2 {
    font-weight: bold;
    color: #000;
    margin-top: 0;
    font-size: 32px;
  }
  .card-wrapper {
    text-align: left;
  }
  .field-name-field-link {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    background: none;
    width: 90%;
    a:link {
      background: @sib-primary;
      text-transform: none;
      text-align: center;
      line-height: normal;
      padding: 20px 70px;
      transition:0.2s all;
      display: inline-block;
      width: 100%;
      &:hover {
        background: @sib-secondary;
      }
    }
    a:after {
      display: none;
    }
  }

  @media @tablet {
    min-height: 600px;
    background-size: 70%;
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 18px!important;
    }
    .field-name-field-link {
      font-size: 0.9em;
      width: auto;
      bottom: 30px;
    }
  }
  @media @normal {
    min-height: 650px;
    background-size: 65%;
    h2 {
      font-size: 52px;
    }

    .card-wrapper {
      padding-top: 70px;
    }
  }

  @media @wide {
    min-height: 700px;
    background-size: 50%;

    .card-wrapper {
      padding-top: 70px;
    }
  }

}