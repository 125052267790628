.card-testimonial {
  padding: 0 !important;
  background-color: #F5F5F5;
  margin-left: -15px;
  margin-right: -15px;

  .card-wrapper {
    @media @normal {
      padding: 80px 0;
    }
    text-align: center;
  }

  .field-name-field-quote {
    font-size: 22px;
    color: #12ADDA;
    font-family: 'Montserrat', sans-serif;
    padding: 0 35px;
    background-image: url(/sites/all/themes/good_finance_theme/images/Icon-quote-left.png),url(/sites/all/themes/good_finance_theme/images/Icon-quote-right.png);
    background-repeat: no-repeat,no-repeat;
    background-position: left 12px,right 12px;
    background-size: 30px;
    margin: 30px auto;
    display: block;
    font-weight: bold;
    @media @tablet {
      background-size: 40px 33px;
      padding: 0 55px;
    }
    @media @normal {
      font-size: 2em;
      background-size: 64px 54px;
      padding: 0 90px;
    }

  }
  .field-name-field-name-center {
    color: #4E4E4E;
    font-size: 1.2em;
  }
}
