.card-stats-and-copy {
  margin-left: -15px;
  margin-right: -15px;
  overflow: visible !important;
  overflow-x: hidden;
  overflow-y: visible;

  .field-name-field-stat {
    color: white;
    font-size: 3em;
    @media @wide {
      font-size: 75px;
    }
    font-weight: bold;
    letter-spacing: -1.64px;
    line-height: 92px;
    text-align: center;
    text-shadow: 0 2px 8px #1295BA;
    margin-top: 0.5em;
    margin-bottom: 0;
  }

  .field-name-field-stat-description {
    margin: auto;
    color: #024F66;
    font-size: 1.2em;
    padding: 0 30px;
    @media @wide {
      font-size: 28px;
      width: 360px;
      padding: 0 10px;
    }
    font-weight: bold;
    letter-spacing: -0.61px;
    line-height: 39px;
    text-align: center;
  }

  .field-name-field-body {
    font-family: 'museo-sans', sans-serif;
    p {
      font-size: 1.2em !important;
      margin-top: 1em;
    }
  }

  .card-wrapper {
    overflow: visible;
    padding: 0;
    @media @wide {
      margin-top: -35px;
    }
    background-color: transparent !important;
  }

  .group-left, .group-right {
    float: none !important;
    width: 100% !important;
    padding: 20px 15px;
    @media @normal {
      padding: 0;
      width: 50% !important;
      float: left !important;
    }
  }
  .group-left {
    @media @wide {
      margin-top: 100px;
    }
  }
  .group-right {
    pointer-events: none;
    position: relative;
    z-index: 1;
    .field-name-field-stats {
      padding: 80px 0 30px;
    }

    &:before {
      content: ' ';
      display: block;
      top: -35px;
      background: url(../../images/bg-skew.svg) no-repeat;
      background-size: cover;
      position: absolute;
      right: 0;
      z-index: -1;
      bottom: -80px;
      left: -15px;
    }
  }

}
