/**
 * Components.
 * Blocks
 * Displays
 * Menus
 * Views
 */

/**
 * Components
 */

a.glossary {
  padding: 0 3px;
  color: @header-secondary;
  text-decoration: underline;
  border: 0;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
}

.tooltip {
  &.in {
    opacity: 1;
  }
  &.bottom .tooltip-arrow {
    border-bottom-color: #fff;
    margin-left: -8px;
    border-width: 0px 8px 8px;
  }
  &.bottom {
    padding: 8px 0 0;
  }
}

.tooltip-inner {
  color: @text-color;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  padding: 0;
  span {
    display: block;
    font-size: 14px;
    text-align: left;
    padding: 15px 13px;
    line-height: 16px;
  }
  .tooltip-title {
    border-bottom: 1px solid @header-secondary;
    color: @header-secondary;
    font-size: 16px;
    padding: 5px 13px;
  }
}

/**
 * Blocks
 */
.sidebar .block,
.block-multiblock {
  margin-bottom: 40px;
  background-color: #fff;
  padding-bottom: 20px;
  .block-title {
    margin-top: 0;
    font-size: 18px;
    padding: 5px 0px;
    font-weight: bold;
    
    a {
      color: #000;
    }
  }
  p {
    padding: 0 11px;
    a {
      color: @text-color;
    }
  }
  ol, ul {
    padding: 30px 30px 0px 50px;
  }
  .view-content {
    padding: 11px;
  }
  .more-link {
    padding: 11px;
    a {
      background-color: #cf4960;
      border-radius: 6px;
      color: #fff;
      padding: 10px 30px;
      display: block;
      text-align: center;
      &:hover {
        text-decoration: none;
        background-color: #e5516a;
      }
    }
  }
  .view-flagged-measures,
  .view-beneficiaries {
    .view-content {
      padding: 0px;
    }
    .views-row a {
      padding: 7px 15px;
    }
  }
  .view-flagged-measures {
  }
  .view-beneficiaries {
  }
  ul.menu.nav {
    li a {
      color: lighten(@text-color, 40%);
      border: 0;
      padding: 8px 10px;
      font-size: 14px;
      &.active-trail {
        font-weight: bold;
        color: #000;
      }
    }
  }
}

.block-bean-pagination-cta {
  background-color: #12adda;
  @media @normal {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    overflow: hidden;
    &:before,
    &:after {
      content: normal;
    }

    .bean-pagination-cta {
      display: flex;
      flex-wrap: nowrap;
      .field {
        display: flex;
        align-items: center;
      }
      .field-name-field-pag-cta-number {
        background-color: transparent;
        position: relative;
        background-image: url('../../images/pag-cta-bg.png');
        background-position: center;
        background-repeat: no-repeat;
        z-index: 999;
        right: -60px;
        width: 100px;
        .field-items {
          top: 0;
          margin: 0 auto;
        }
        //&:before,
        //&:after{
        //  content: '';
        //  background-color: #f8f8f6;
        //  display: block;
        //  width: 40px;
        //  height: 200px;
        //  position: absolute;
        //}
        //&:before{
        //  transform: rotate(90deg);
        //}
        //&:after{
        //  transform: rotate(-40deg);
        //}
      }
      .field-name-field-pag-cta-link {
        padding: 40px 20px 40px 60px;
      }
    }
  }
  .block-title {
    color: #fff;
    font-size: 24px;
    line-height: 1.38;
    text-align: center;
    padding: 44px 25px;
    font-weight: normal;
    margin: 0;
  }
  .field-name-field-pag-cta-number {
    margin: 0;
    background-color: #f8f8f6;
    .field-items {
      background-color: #fff;
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      margin: 0 auto -25px;
      padding: 5px;
      position: relative;
      top: -10px;
      z-index: 10;
      .field-item {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        color: #fff;
        text-align: center;
        background-color: #12adda;
        line-height: 50px;
        font-size: 17px;
      }
    }
  }
  .field-name-field-pag-cta-link {
    background-color: #fff;
    text-align: center;
    margin: 0;
    padding: 40px 20px;
    a.btn {
      background-color: #933c86;
      border-color: #933c86;
      margin: 0;
      &:hover, &:focus {
        background-color: #b249a3;
        border-color: #b249a3;
      }
    }
  }
}

.social-investment-sub-menu {
  margin-bottom: 40px;
  .row {
    a {
      background-color: #fff;
      text-align: center;
      padding: 15px;
      font-size: 17px;
      letter-spacing: 0.6px;
      display: block;
      margin-bottom: 20px;
      @media @tablet {
        margin-bottom: 0;
        &.active {
          position: relative;
          &:after {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: #fff;
            border-width: 15px;
            margin-left: -15px;
          }
        }
      }
      &:hover,
      &:focus,
      &.active {
        text-decoration: none;
        color: @text-color;
      }
    }
  }
}

/**
 * DISPLAY LAYOUTS
 */
.ds-2col-stacked-fluid {
  .make-row();
  .group-header {
    .make-xs-column(12);
  }
  .group-left {
    .make-xs-column(12);
    .make-md-column(4);
  }
  .group-right {
    .make-xs-column(12);
    .make-md-column(8);
  }
  .group-footer {
    .make-xs-column(12);
  }
  &.group-one-column {
    .group-left {
      .make-xs-column(12)
    }
  ;
    .group-right {
      .make-xs-column(12)
    }
  ;
  }
}

.ds-1col {
  .field-type-image {
    margin-bottom: 10px;
  }
  .field-name-title h4 {
    margin-top: 0;
  }
}

.ds-2col-fluid,
.ds-2col {
  .make-row();
  .group-left {
    .make-xs-column(6);
    .make-md-column(4);
  }
  .group-right {
    .make-xs-column(6);
    .make-md-column(8);
  }
  &.group-one-column {
    .group-left {
      .make-xs-column(12)
    }
  ;
    .group-right {
      .make-xs-column(12)
    }
  ;
  }
}

.field.field-name-title {
  margin-bottom: 0;
}

.field-name-field-image .img-responsive {
  width: 100%;
}

.field-name-body {
  h4 {
    margin-top: 20px;
  }
}

.node-teaser,
.view-mode-grid {
  h3 {
    margin-bottom: 20px;
  }
  &.ds-2col-fluid,
  &.ds-2col {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.view-mode-full {
  .field-name-field-social-issues {
    border-top: solid 1px rgba(113, 115, 115, 0.3);
    border-bottom: solid 1px rgba(113, 115, 115, 0.3);
    padding: 6px 0;
  }
  .field-name-field-logo img {
    width: 100%;
  }
}

.node-teaser {
  .field-name-field-teaser-image,
  .field-name-field-logo {
    margin-top: 22px;
  }
  .field-name-field-introduction {
    padding-left: 0;
    padding-right: 0;
  }
}

.view-mode-grid {
  .field-name-field-introduction {
    padding-left: 0;
    padding-right: 0;
  }
}

.field {
  margin-bottom: 20px;
  body.front &.field-name-field-cards {
    margin-bottom: 0;
  }
  .field-label {
    font-weight: normal;
  }
  &.field-label-inline {
    .field-label, .field-items {
      display: inline-block;
    }
  }
}

.field-name-post-type-date {
  font-family: @font-family-headers;
}

.field-name-field-social-issues {
  .field-label {
    float: left;
    margin-right: 7px;
    font-weight: bold;
    &::after {
      content: ' | '
    }
  }
  .field-items {
    font-size: 13px;
    font-family: @font-family-headers;
  }
}

.field-name-post-last-updated {
  .field-label {
    float: left;
    margin-right: 5px;
    &::after {
      content: ' | '
    }
  }
}

.node .group-contact-info.field-group-div {
  padding-top: 22px;
  padding-bottom: 17px;
  margin-bottom: 40px;
  .field-name-field-address {
    .map-link,
    .location-hidden {
      display: none;
    }
  }

  .field {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .website-button {
    margin-top: 5px;
    margin-bottom: 10px;
    width: 100%;
    a {
      color: #fff;
      text-decoration: none;
      white-space: normal;
    }
  }
}

.node .group-footer {
  .field-name-field-google-maps .field-label {
    display: none;
  }
  .google_map_field_display {
    width: 100%;
    height: 400px;
  }
}

.node .field-group-div {
  border-radius: 5px;
  border: solid 2.5px #e3e3e3;
  padding: 0 22px;
  h3 {
    font-size: @font-size-base-mobile;
    color: @text-color;
    @media @tablet {
      font-size: @font-size-base;
    }
  }
  .field-group-div {
    border: 0;
    padding: 0;
  }
  .field {
    font-size: 14px;
    margin-bottom: 14px;
  }
  @media @tablet {
    .make-row();
    margin-left: 0;
    margin-right: 0;
    .field {
    }

    .field-group-div {
      .make-sm-column(6);
      .make-md-column(6);
    }

    .group-contact-left {
      padding-left: 0;
    }

    .group-contact-right {
      padding-right: 0;
    }
  }
}

.field-name-node-link {
  float: left;
  a {
    color: @text-color;
    border-bottom: solid 2px #cf4960;
    text-decoration: none;
    &:hover {
      color: @text-color;
      border-bottom: solid 2px @text-color;
      text-decoration: none;
    }
  }
}

.field-name-field-investor-type {
  float: right;
  .field-item {
    display: inline-block;
    color: transparent;
    width: 50px;
    height: 50px;
    &:first-child {
      margin-right: 5px;
    }
    &.investors {
      background: url("../../images/icon-investor.svg") no-repeat;
    }
    &.advisors {
      background: url("../../images/icon-advisor.svg") no-repeat;
    }
  }
}

.node-case-study {
  .field-name-field-teaser-image {
    margin-top: 0;
  }
  .group-middle {
    padding: 0 20px;
    h4 {
      margin-top: 0;
    }
  }
  .group-case-study-details {
    border: 0;
    padding: 0;
    font-size: 14px;
    .field {
      margin-bottom: 3px;
      font-size: 14px;
      @media @tablet {
        font-size: 14px;
      }
    }
    .group-middle {
      h4 {
        margin-top: 0;
      }
    }
    .field-label {
      font-weight: bold;
      &:after {
        content: ":";
      }
    }
  }
  &.view-mode-teaser,
  &.view-mode-grid {
    background-color: #fff;
    border: solid 2.5px #2dbded;
    border-bottom: solid 2.5px #2dbded !important;
    border-radius: 5px;
    padding: 20px 8px 25px !important;
    margin-left: 0;
    margin-right: 0;
    .field-name-field-impact-area {
      float: left;
      a {
        margin-right: 10px;
        font-size: 13px;
        line-height: 36px;
      }
    }
    &.view-mode-full {
      & > .row:nth-child(2) {
        display: flex;
        flex-flow: column-reverse;
        flex-wrap: wrap;
        @media @tablet {
          display: block;
        }
      }
      .field-name-field-pdf-attachment {
        text-align: left;
        .file {
          img, .file-size {
            display: none;
          }
        }
        a {
          .btn;
          .btn-default;
          margin-right: 0;
        }
      }
    }
    &.view-mode-teaser,
    &.view-mode-grid {
      background-color: #fff;
      border: solid 2.5px #2dbded;
      border-bottom: solid 2.5px #2dbded !important;
      border-radius: 5px;
      padding: 20px 8px 25px !important;
      margin-left: 0;
      margin-right: 0;
      .field-name-field-impact-area {
        float: left;
        a {
          margin-right: 10px;
          font-size: 13px;
          line-height: 36px;
        }
      }
      .field-name-node-link {
        a {
          margin-right: 0;
          margin-bottom: 0;
        }
      }
      .group-footer {
        border-top: solid 1px rgba(113, 115, 115, 0.3);
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 10px;
        margin-right: 10px;
        width: ~"calc(100% - 20px)";
        padding-top: 16px;
        .field {
          margin-bottom: 0;
        }
      }
      .field-name-field-introduction {
        p {
          font-size: 14px;
        }
      }
    }
    &.view-mode-teaser {
      img.img-responsive {
        width: 100%;
      }
    }
    &.ds-3col-stacked > .group-left,
    &.ds-3col-stacked > .group-middle,
    &.ds-3col-stacked > .group-right {
      width: ~"calc(100% - 20px)";
      float: none;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .node-fund {
    .field-name-fund-active-label .field-item {
      background-image: url('../../images/icon-closed.png');
      background-size: 31px 31px;
      background-position: 0 0;
      background-repeat: no-repeat;
      padding-left: 38px;
      line-height: 31px;
      font-weight: bold;
      color: #b21f20;
    }

    .field-name-node-link {
      float: right;
      a {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
    .group-footer {
      border-top: solid 1px rgba(113, 115, 115, 0.3);
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 10px;
      margin-right: 10px;
      width: ~"calc(100% - 20px)";
      padding-top: 16px;
      .field {
        margin-bottom: 0;
      }
    }
    .field-name-field-introduction {
      p {
        font-size: 14px;
      }
    }
  }
}

.node-fund {
  .field-name-fund-active-label .field-item {
    background-image: url('../../images/icon-closed.png');
    background-size: 31px 31px;
    background-position: 0 0;
    background-repeat: no-repeat;
    padding-left: 38px;
    line-height: 31px;
    font-weight: bold;
    color: #b21f20;
  }
  &.view-mode-teaser,
  &.view-mode-grid {
    .field {
      margin-bottom: 10px;
    }
    .field-name-title,
    .field-name-fund-active-label {
      float: left;
    }
    &.view-mode-teaser .field-name-fund-active-label {
      padding-top: 22px;
      margin-left: 20px;
    }
    &.view-mode-grid .field-name-title {
      margin-top: 15px;
    }
    &.view-mode-grid .field-name-fund-active-label {
      padding-top: 12px;
      margin-left: 20px;
    }
    .field-name-field-introduction {
      clear: left;
      font-size: 14px;
    }
    .field-name-field-external-link a {
      color: #802d77;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

/**
 * Menus - DISPLAY MAIN NAV in MENU BLOCK
 */
#navbar {
  background-color: #fff;
  border: 0;
  min-height: 0;
  margin-top: 0 !important;
  .navbar,
  .navbar-default {
    margin: 0 auto 20px auto;
    border: 0 !important;
    background: none !important;
    min-height: 30px;
    z-index: 200;
    padding: 0;
  }
  .navbar-collapse {
    padding: 0;
  }
}
.region-header {

  .nav > li > a:hover {
    // Override bootstrap style.
    background: transparent;
  }
  .block-menu-block {
    ul.menu {
      line-height: 40px;
      display: flex;
      flex-direction: row;
      width: 100%;
      li {
        font-family: @font-family-headers;
        a {
          line-height: 20px;
          position: relative;
          font-weight: bold;
          font-size: 12px;
          color: #000;
          border-bottom: solid 3px transparent;
          transition: .1s .0s;
          padding: 5px 30px;
          @media @normal {
            font-size: 12px;
          }
          @media @wide {
            font-size: 14px;
            letter-spacing: -0.46px;
            line-height: 22px;
            font-weight: bold;
          }
        }
        &:hover > ul {
          display: block;
        }
      }
      li:hover {
        background: #fff;
      }
      ul {
        width: 100%;
        background-color: #fff;
        margin: 0;
        padding: 0;
        display: none;
        transition: .6s;
        overflow: hidden;
        position: absolute;
        top: 100%;
        z-index: 10;
        li {
          float: none;
          display: block;
          line-height: 20px;
        }
        a {
          padding: 5px 10px;
          display: block;
          line-height: 1.2em;
        }
      }
    }
  }
  .menu-name-main-menu > ul > li.active-trail > a:after {
    content: '';
    height: 2px;
    position: absolute;
    background: @sib-info;
    bottom: 0;
    left: 25px;
    right: 25px;
  }
}
.not-front {
  .region-header .menu-name-main-menu > ul.menu > li.expanded {
    border: 1px solid transparent;
    border-bottom: 0;

    & > ul {
      border: 1px solid transparent;
      border-top: 0;
    }
  }
  .region-header .menu-name-main-menu > ul.menu > li.expanded:hover {
    border-color:#e3e3e3;
    & > ul {
      border-color: #e3e3e3;
    }
  }
}

.region-header .newsletter-button {
  > p > a {
    position: relative;
    padding-right: 50px;
  }
}

body {
  #navbar {
    margin-bottom: 0.5em;
    @media @wide {
      margin-bottom: 1em;
    }
  }
}

.sidebar .block-menu-block .menu {
  li {
    line-height: 10px;
    font-size: 15px;
    margin: 0;
    a {
      font-weight: normal;
      padding: 10px;
      border-bottom: 1px solid #efefef;
      display: block;
      outline: none;
      color: @brand-primary;
      &:hover {
        background: @navbar-default-link-hover-bg;
      }
      &.active-trail {
        background: @brand-primary;
        color: @navbar-inverse-link-hover-color;
      }
    }
  }
}

.navbar-toggle {
  position: relative;
  line-height: 0;
  background-color: transparent;
  color: #fff;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: .3s .1s;
  margin: 0;
  .icon-bar {
    background: #000;
    height: 3px;
    width: 30px;
    position: relative;
    display: inline-block;
    transition: .3s .1s;
  }
  .icon-bar:before,
  .icon-bar:after {
    transform-origin: 4px center;
    height: 3px;
    width: 30px;
    background: #000;
    content: '';
    transition: .3s .1s;
    display: inline-block;
    position: absolute;

  }
  .icon-bar:before {
    top: 8px;
  }
  .icon-bar:after {
    top: -8px;
  }
  &.toggle-active {
    transform: rotate3d(0, 0, 1, 180deg);
    .icon-bar:after {
      transform: rotate3d(0, 0, 1, 45deg);
    }
    .icon-bar {
      background-color: transparent;
    }
    .icon-bar:before {
      transform: rotate3d(0, 0, 1, -45deg);
    }
    .icon-bar:before,
    .icon-bar:after {
      transform-origin: 1px center;
      height: 5px;
      width: 24px;
    }
  }
  span {
    display: none;
  }
}

.sidr {
  width: 100%;
  display: none;
  background: #f6f6f6;
  border-bottom: solid 1px #d4d4d4;
  position: relative;
  top: -1px;
  &.sidr-open {
  }
}

.sidr.right {
  left: auto;
  right: -260px
}

.sidr.left {
  left: -260px;
  right: auto
}

.sidr {
  .block {
    margin-bottom: auto;
  }
  .inner {
    text-align: center;
  }
  .slide-nav {
    padding: 20px 0px 0;
    a {
      color: #802d77;
    }
    a:hover, a.active, .active > a {
      background: #12adda;
      color: #fff;
    }
    .expanded {
      ul {
        padding: 0;
        display: none;
        li {
          padding: 2px 0;
        }
        a {
          padding: 3px 15px;
          font-size: 0.8em;
          margin: 5px 0;
          &:hover {
            text-decoration: none;
          }
        }
      }
      &.active ul {
        display: block;
      }
    }
  }
  .slide-content {
    padding: 0px 20px;
  }
  .region-header #views-exposed-form-site-wide-search-page {
    float: none;
    margin: 0 auto;
    display: inline-block;
  }
}

/**
 * if sidr open, keep menu
 */
.sidr-main-open {
  #page-header, #page-header.nav-up {
    background: transparent;
    top: 0 !important;
  }
  .logo-container {
    display: none;
  }
}

.navbar-administration.sidr-main-open {
  #page-header, #page-header.nav-up {
    background: transparent;
    top: 39px !important;
  }
}

body.navbar-tray-open.navbar-vertical.navbar-fixed {
  margin-left: 240px;
}

body.navbar-tray-open.navbar-fixed.navbar-vertical #navbar-administration.navbar-oriented {
  width: 240px;
}

#navbar-administration.navbar-oriented .navbar-tray-vertical {
  width: 240px;
}

.block-progress-bar,
.menu-name-menu-investment-menu {
  .make-row();
  @media @tablet {
    margin-bottom: 20px;
  }
  ul {
    padding: 0;
    margin-bottom: 0;
    li {
      list-style: none;
      color: #2ebcec;
      background-image: url('../../images/icon-breadcrumb-arrow.png');
      background-repeat: no-repeat;
      background-size: 15px 28px;
      background-position: 10px center;
      line-height: 43px;
      padding-left: 40px;
      margin-bottom: 0;
      font-size: 14px;
      &.active {
        color: #fff;
        background-color: #28b4e9;
        background-image: url('../../images/icon-breadcrumb-arrow-active.png');
        a {
          color: #fff;
          &:hover, &:focus {
            background-color: transparent;
          }
        }
      }
      a {
        &:hover, &:focus {
          background-color: transparent;
        }
      }
    }
  }
  @media @tablet {
    margin-left: 0;
    margin-right: 0;
    ul {
      li {
        background-image: none;
        background-color: transparent;
        .make-sm-column(4);
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        span, a {
          background-color: #89d6f0;
          color: #fff;
          line-height: 42px;
          height: 42px;
          display: block;
          margin-right: 20px;
          margin-left: 20px;
          position: relative;
          padding: 0;
          &::before {
            content: '';
            position: absolute;
            background-image: url('../../images/big_arrow_sprite.png');
            background-repeat: no-repeat;
            background-size: 40px 126px;
            background-position: 0 -84px;
            width: 20px;
            height: 42px;
            top: 0px;
            left: -20px;
          }
          &::after {
            content: '';
            position: absolute;
            background-image: url('../../images/big_arrow_sprite.png');
            background-repeat: no-repeat;
            background-size: 40px 126px;
            background-position: 0 0;
            width: 20px;
            height: 42px;
            top: 0px;
            right: -20px;
          }
        }
        a:hover, a:focus {
          background-color: #2ebcec;
          &::before {
            background-position: -20px -84px;
          }
          &::after {
            background-position: -20px 0;
          }
        }
        &:first-child a:hover::before {
          background-position: -20px -42px;
        }
        &:first-child span::before,
        &:first-child a::before {
          content: '';
          position: absolute;
          background-image: url('../../images/big_arrow_sprite.png');
          background-repeat: no-repeat;
          background-size: 40px 126px;
          background-position: 0 -42px;
          width: 20px;
          height: 42px;
          top: 0px;
          left: -20px;
        }
        &.active {
          background-image: none;
          background-color: transparent;
          span, a, a:hover {
            background-color: #2ebcec;
            &::before {
              background-position: -20px -84px;
            }
            &::after {
              background-position: -20px 0;
            }
          }
          &:first-child span::before,
          &:first-child a::before {
            background-position: -20px -42px;
          }
        }
      }
    }
  }
}

.page-wizard {
  #block-system-main a.btn.btn-default {
    background-color: #802d77;
    border-color: #802d77;
  }
  #wizard-form-entityform-edit-form button {
    background-color: #802d77;
    border-color: #802d77;
  }
  #block-progress-bar-wizard-progress-bar ul li {
    span, a {
      @media @tablet {
        background-color: #BF96BB !important;
      }
      &::before {
        background-image: url('../../images/big_arrow_sprite_purple.png') !important;
      }
      &::after {
        background-image: url('../../images/big_arrow_sprite_purple.png') !important;
      }
    }
    &:first-child span::before,
    &:first-child a::before {
      background-image: url('../../images/big_arrow_sprite_purple.png') !important;
    }
    &.active span, a, a:hover {
      @media @tablet {
        background-color: #802d77 !important;
      }
    }
  }
}

/**
 * Views - remove padding/margin etc
 */
.views-row {
  // .border-light(0 0 1px 0);
  // padding: 0 0 20px 0; margin-bottom: 0 0 20px 0;
  .border-light(0 0 1px 0);
  padding: 0 0 6px 0;
  margin: 0 0 4px 0;
}

.view-latest-listing-blocks {
  .border-light(0 0 1px 0);
  padding-bottom: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
  @media @tablet {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.views-row-last {
  .ds-2col-stacked-fluid,
  .ds-2col,
  .ds-2col-stacked-fluid,
  .ds-2col-fluid {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0 none;
  }
}

.view-ineligible-results {
  .views-row {
    border-bottom: 0;
    margin-bottom: 15px;
  }
}

.view-wizard-results-product-types {
  .make-row();
  .views-row {
    .make-sm-column(6);
    .make-md-column(3);
    @media @tablet {
      &:nth-child(2n+1) {
        clear: left;
      }
    }
    @media @normal {
      &:nth-child(2n+1) {
        clear: none;
      }

      &:nth-child(4n+1) {
        clear: left;
      }
    }
    border: 0;
    .entity {
    }
  }
}

.block-views {
  .block-title {
    margin-bottom: 20px;
  }
}

.view-other-funding-options.view-display-id-block {
  .make-row();
  .views-row {
    .make-sm-column(6);
    .make-md-column(4);
    margin-bottom: 25px;
    @media @tablet {
      &:nth-child(2n+1) {
        clear: left;
      }
    }
    @media @normal {
      &:nth-child(2n+1) {
        clear: none;
      }

      &:nth-child(3n+1) {
        clear: left;
      }
    }
    border: 0;
  }
}

.form-item-products-type,
.form-item-product-type,
.form-item-field-product-type-target-id-entityreference-filter,
.form-item-field-impact-area-tid,
.form-item-social-impact {
  .form-control {
    min-height: 34px;
    height: auto;
  }
  .bef-checkboxes {
    .make-row();
    .form-item {
      //.make-sm-column(6);
      .make-xs-column(12);
      .form-control {
        float: left;
        width: auto;
        margin-right: 10px;
      }
    }
  }
}

.view-filters {
  background-color: #fff;
  border: solid 2.5px #e3e3e3;
  padding: 25px 28px 20px;
  position: relative;
  .views-widget-filter-field_region_tid {
    @media @tablet {
      padding-right: 20px !important;
    }
  }
  .views-widget-filter-field_product_type_target_id_entityreference_filter {
    @media @tablet {
      margin-right: 20px;
      width: ~"calc(50% - 20px)" !important;
    }
  }
  label {
    font-weight: normal;
    font-size: 14px;
  }
  .views-widget-filter-field_region_tid,
  .views-widget-filter-field_borrowing_amount_tid {
    .make-xs-column(12);
    .make-sm-column(6);
  }
  .views-widget-filter-field_product_type_target_id_entityreference_filter,
  .views-widget-filter-field_impact_area_tid {
    .make-xs-column(12);
    .make-sm-column(6);
    .form-item {
      margin-bottom: 5px;
      /*@media @normal{
              &:nth-child(3n+1){
                      clear: left;
              }
      }*/
    }
    .form-control {
      display: inline;
    }
    & > label {
      cursor: pointer;
      padding-right: 40px;
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 14px;
        background-image: url('../../images/blue-arrow.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 14px;
        right: 25px;
        transition: 0.2s;
        transform: rotate(90deg);
      }
    }
    &.open > label:after {
      transform: rotate(-90deg);
    }
  }
  .views-exposed-widget {
    padding: 0;
    & > label {
      margin-bottom: 0px;
    }
    .form-control {
      margin-left: 0;
      margin-right: 0;
      &.form-checkboxes {
        border: 0;
        padding: 0;
        box-shadow: 0 0 0;
      }
    }
    .panel-body {
      padding: 20px 15px 15px;
    }
    &.closed {
      .panel-body {
        display: none;
      }
    }
  }
  .views-reset-button {
    button {
      background-color: transparent;
      color: #11addb;
      font-size: 14px;
      &:hover {
        background-color: transparent;
        box-shadow: 0px 0px 0px 1px #11addb inset;
        color: #11addb;
      }
      &:active, &:focus {
        background-color: #11addb;
        border-color: #11addb;
        color: #fff;
      }
    }
  }
  .views-submit-button {
    float: right;
    padding: 0;
    button {
      margin: 0 !important;
      font-size: 14px;
      &:hover {
        border-color: #17b5ea;
      }
    }
  }
  .views-reset-button {
    .pull-right;
    button {
      &.form-submit {
        margin-top: 0 !important;
      }
    }
  }
}

.navbar-administration.navbar-tray-open .view-investors-and-advisors {
  #filters-arrow {
    top: 182px;
  }
}

.navbar-administration .view-investors-and-advisors {
  #filters-arrow {
    top: 142px;
  }
}

.view-investors-and-advisors {
  .view-filters {
    max-width: 100%;
    margin-bottom: 20px;
    z-index: 100;
    &.iamfixed {
      @media only screen and (max-width: 980px) {
        top: 55px;
      }
      @media only screen and (max-width: @screen-xs) {
        top: 55px;
      }
    }

    &.sticky {
      position: fixed;
      display: none;
      top: 142px;
      @media only screen and (max-width: @screen-sm) {
        top: 92px;
      }
      .views-widget-filter-field_investor_type_value {
        position: static !important;
        a {
          margin-bottom: 0!important;
          @media only screen and (max-width: @screen-sm) {
            margin-bottom: 5px!important;
          }
        }
      }
    }
  }
  #filters-arrow {
    width: 100%;
    height: 40px;
    top: 105px;
    left: 0;
    position: fixed;
    z-index: 100;
    fill: #fff !important;
    padding-top: 6px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    background-color: #fff;
    border: solid 2.5px #e3e3e3;
    .inner {
      background: url(../../images/icon-chevron-down.svg) no-repeat right;
      max-width: 1170px;
      margin: 0 auto;
      .text {
        font-weight: bold;
        color: #2fb4ea;
        text-align: right;
        padding-right: 30px;
      }
    }
    @media only screen and (max-width: 980px) {
      top: 55px;
    }
    @media only screen and (max-width: @screen-xs) {
      top: 55px;
    }
  }
  #edit-investor-type-wrapper {
    position: absolute;
    bottom: -110px;
    text-align: center;
    left: 0;
    right: 0;
    @media @tablet {
      text-align: left;
      display: flex;
      bottom: -110px;
      left: 20px;
      right: auto;
    }
    label {
      display: none;
    }
    .form-item {
      display: flex;
      justify-content: center;
      width: 100%;
      @media @tablet {
        justify-content: flex-start;
      }
      a {
        .btn;
        color: #11addb;
        margin-right: 0;
        border-radius: 0;
        border-color: #2aa3d4;
        &.active {
          background-color: #2aa3d4;
          color: #fff;
          box-shadow: none;
        }
        &:hover:not(.active) {
          color: #2aa3d4;
        }
      }
      .form-type-bef-link:first-child a {
        border-radius: 6px 0 0 6px;
        border-right: none;
      }
      .form-type-bef-link:nth-child(2) a {
        background-image: url("../../images/icon-investor.svg");
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: calc(100% - 8px) center;
        padding-right: 40px;
      }
      .form-type-bef-link:last-child a {
        border-radius: 0 6px 6px 0;
        border-left: none;
        background-image: url("../../images/icon-advisor.svg");
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: calc(100% - 8px) center;
        padding-right: 40px;
      }
      @media (max-width: @screen-xs-max) {
        .form-type-bef-link:nth-child(n) a {
          background-image: none;
          padding: 10px 10px 8px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .view-content {
    background-color: #fff;
    padding: 80px 20px 10px;
    .views-row-1 {
      border-width: 1px 0;
    }
  }
}

.view-case-studies {
  .view-filters {
    margin-bottom: 30px;
  }
  .views-row {
    border: 0;
    margin-bottom: 30px;
  }
  .group-right .field-label {
    font-weight: bold;
  }
  @media @tablet {
    .view-content {
      .make-row();
      display: flex;
      flex-wrap: wrap;
      .views-row {
        display: flex;
        .make-sm-column(6);
        .make-lg-column(4);
        .node-case-study {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          .group-right {
            flex-grow: 1;
          }
        }
      }
    }
  }
}

.view-header h4 a {
  color: #2ebcec;
}

.page-latest {
  #block-facetapi-g973f0f9tts7ndnflwyvykrhy0gcexbs .facetapi-facetapi-links {
    padding: 0;
    li {
      float: left;
      margin-right: 20px;
      border: solid 1px #933c86;
      border-radius: 6px;
      height: 36px;
      display: block;
      line-height: 36px;
      width: 145px;
      text-align: center;
      &:hover {
        background-color: transparent;
        box-shadow: 0px 0px 0px 1px #933c86 inset;
        a {
          color: #933c86;
          text-decoration: none;
        }
      }
      &:active, &:focus {
        background-color: #933c86;
        border-color: #933c86;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
      a {
        color: #933c86;
        font-size: 14px;
        height: 36px;
        display: block;
        line-height: 36px;
        width: 145px;
        &.facetapi-active {
          width: auto;
          display: inline-block;
        }
      }
    }
  }
  #filters-top {
    margin-bottom: 20px;
  }
  #filters-bottom {
    background-color: #fff;
    border: solid 2.5px #e3e3e3;
    padding: 25px 28px;
    position: relative;
    margin-bottom: 20px;
    &.filters-closed {
      .block-facetapi {
        display: none;
      }
      .region {
        @media @normal {
          padding-bottom: 0 !important;
        }
      }
    }
    &.more-filter {
      .region {
        padding-bottom: 45px;
      }
    }
    .filter-trigger {
      position: absolute;
      bottom: 20px;
      right: 20px;
      margin: 0;
    }
    .region {
      .make-row();
      @media @normal {
        padding: 0;
      }
    }
    #block-views-exp-latest-search-page {
      .make-xs-column(12);
      .form-submit {
        background-color: transparent;
        border: 0;
        background-image: url('../../images/icon-search.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 24px 24px;
        text-indent: -90000px;
        display: block;
        width: 24px;
        height: 24px;
        margin-top: 6px;
        margin-left: 10px;
      }
      .views-widget {
        display: inline-block;
      }
      .views-exposed-widgets {
        margin-bottom: 0;
      }
      .views-exposed-widget {
        padding: 0;
        label {
          font-size: 14px;
          font-weight: normal;
          display: none;
          margin-right: 15px;
          @media @tablet {
            display: inline-block;
          }
        }
        /*
        @robertoperuzzo
Added bootstrap panel classes to exposed filter to solve issue #71:
"It's not very clear that product type and social issue area are two
different groups of terms".
        */
        label.panel-heading {
          display: block;
        }
      }
      .views-widget-filter-search_api_views_fulltext {
        max-width: calc(~"100% - 40px");
        input.form-text {
          max-width: 100%;
          min-width: auto;
          margin-bottom: 0;
        }
      }
      .views-submit-button {
        width: 40px;
      }
    }
    .block-facetapi {
      .make-xs-column(12);
      .make-sm-column(6);
      h2.block-title {
        font-size: 14px;
        color: @text-color;
        font-weight: normal;
        font-family: @font-family-sans-serif;
      }
      .facetapi-facetapi-links {
        padding: 0;
        li {
          font-size: 14px;
        }
      }
    }
  }
}

/**
 * WIZARD
 */
.page-wizard-results {
  .main-container {
    padding-bottom: 48px;
    @media @tablet {
      padding-bottom: 44px;
    }
  }
  .main-content-column {
    position: static;
  }
  .view-wizard-results-product-types {
    padding-bottom: 40px;
  }
}

.wizard-investors-link {
  text-align: center;

  a.btn {
    background-color: #802d77;
    border-color: #802d77;
    font-size: 20px;
    max-width: 500px;
    text-align: center;
    margin: 0 auto;
    &:hover {
      background-color: #a13896;
      border-color: #a13896;
      color: #fff;
    }
  }

}

.page-wizard {
  .submitted,
  .redirect-list {
    display: none;
  }
  .intro_wrapper {
    .make-row();
    margin-left: 0;
    margin-right: 0;
    border: solid 2.5px #e3e3e3;
    background-color: #ffffff;
    border-radius: 0px;
    padding: 40px 26px 20px;
    @media @tablet {
      position: relative;
    }
    .copy-wrapper,
    .edit-wrapper {
      margin-top: 20px;
      .make-sm-column(6);
    }
    .copy-wrapper {
      padding-left: 0;
      label {
        font-size: 14px;
      }
      input {
        min-width: 100%;
        padding: 0 10px;
      }
    }
    .edit-wrapper {
      padding-left: 0;
      padding-right: 0;
      @media @tablet {
        padding-left: 10px;
        position: static;
        .btn {
          position: absolute;
          bottom: 20px;
          right: 26px;
        }
      }
    }
    .copy-wrapper label {
      display: block;
    }
    .btn {
      margin-bottom: 0;
      margin-right: 0px;
    }
  }
  h3 strong {
    color: #802d77;
  }
}

/**
 * ENTITIES
 */
.isotope-container {
  margin-left: -12px;
  margin-right: -12px;
}

.isotope-element {
  .wizard-product-type {
    margin: 10px 0px 10px;
    @media @tablet {
      margin: 10px 12px 10px;
    }
  }
  .node-case-study {
    margin: 10px 12px 10px;
  }
}

.wizard-product-type {
  border-width: 2.5px;
  border-style: solid;
  margin: 10px 0px 10px;
  padding: 20px 18px 72px;
  background-color: #ffffff;
  border-radius: 5px;
  position: relative;
  @media @tablet {
    margin: 10px 12px 10px;
  }
  &.product-category-equity {
    border-color: #11addb;
    button {
      background-color: #2aa3d4;
      color: #fff;
      font-size: 14px;
      border-color: #2aa3d4;
      &:hover {
        background-color: #2fb4ea;
        border-color: #2fb4ea;
        color: #fff;
      }
      &:active, &:focus {
        background-color: #11a2cd;
        border-color: #11a2cd;
        color: #fff;
      }
    }
  }
  &.product-category-shares {
    border-color: #11addb;
    button {
      background-color: #2aa3d4;
      color: #fff;
      font-size: 14px;
      border-color: #2aa3d4;
      &:hover {
        background-color: #2fb4ea;
        border-color: #2fb4ea;
        color: #fff;
      }
      &:active, &:focus {
        background-color: #11a2cd;
        border-color: #11a2cd;
        color: #fff;
      }
    }
  }
  &.product-category-borrow {
    border-color: #802d77;
    button {
      background-color: #802d77;
      color: #fff;
      font-size: 14px;
      border-color: #802d77;
      &:hover {
        background-color: #a13896;
        border-color: #a13896;
        color: #fff;
      }
      &:active, &:focus {
        background-color: #74276c;
        border-color: #74276c;
        color: #fff;
      }
    }
  }
  &.product-category-other {
    border-color: #cf4960;
    button {
      background-color: #cf4960;
      color: #fff;
      font-size: 14px;
      border-color: #cf4960;
      padding: 8px 28px 6px;
      &:hover {
        background-color: #e5516a;
        border-color: #e5516a;
        color: #fff;
      }
      &:active, &:focus {
        background-color: #c04459;
        border-color: #c04459;
        color: #fff;
      }
    }
  }
  &.product-category-tool {
    border-color: #cf4960;
    button {
      background-color: #cf4960;
      color: #fff;
      font-size: 14px;
      border-color: #cf4960;
      padding: 8px 28px 6px;
      &:hover {
        background-color: #e5516a;
        border-color: #e5516a;
        color: #fff;
      }
      &:active, &:focus {
        background-color: #c04459;
        border-color: #c04459;
        color: #fff;
      }
    }
  }
  button::after {
    content: ' +';
  }
  .field-name-product-category-read-more {
    position: absolute;
    left: 18px;
    bottom: 27px;
    max-width: ~"calc(100% - 36px)";
    .btn {
      max-width: 100%;
    }
  }
  .btn,
  .field-name-product-category-read-more {
    margin-bottom: 0px;
    margin-right: 0px;
  }
  .field-name-product-category-copy {
    text-align: right;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #9b9b9b;
  }
  h4 {
    font-size: 16px;
    font-weight: bold;
  }
  .field-name-field-introduction {
    font-size: 14px;
  }
}

.wizard-other-funding-option {
  border: 0;
  border-radius: 5px;
  background-color: #efefef;
  padding: 40px 18px 72px;
  position: relative;
  h4 {
    font-size: 16px;
    font-weight: bold;
  }
  .field-name-product-category-read-more {
    margin-bottom: 0;
    .btn {
      margin-bottom: 0;
    }
  }
  .field-name-field-introduction {
    font-size: 14px;
  }
  .field-name-product-category-read-more {
    position: absolute;
    bottom: 26px;
    left: 18px;
  }
  .btn.btn-default {
    background-color: #933c86;
    color: #fff;
    font-size: 14px;
    border-color: #933c86;
    padding: 8px 28px 6px;
    &:hover {
      background-color: #b249a3;
      border-color: #b249a3;
      color: #fff;
    }
    &:active, &:focus {
      background-color: #803475;
      border-color: #803475;
      color: #fff;
    }
  }
  button::after {
    content: ' +';
  }
}

.field-name-anchor-id {
  padding: 0;
  margin: 0;
  .field-items > .field-item > a::before {
    content: "";
    display: block;
    height: 110px; /* fixed header height*/
    margin: -110px 0 0; /* negative fixed header height */
  }
}

.field-name-field-cards .entity {
  padding: 0;
  &.hide-title .field-name-title {
    display: none;
  }
  &.center-title .field-name-title {
    text-align: center;
  }
}

.card-title-card {
  padding: 10px 0 0;
  .card-wrapper {
    padding: 0;
    @media @normal {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

#block-views-other-funding-options-block {
  border-top: solid 1px rgba(113, 115, 115, 0.3);
  margin-top: 30px;
  padding-top: 30px;
}

.view-mode-full .field-name-field-related-case-studies {
  border-top: solid 1px rgba(113, 115, 115, 0.3);
  margin-top: 50px;
  padding-top: 30px;
  & > .field-label {
    .h2;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  & > .field-items {
    & > .field-item {
      margin-bottom: 30px;
    }
  }
}

.field-name-field-cards .entity.card-three-column-v1 {
  .card-wrapper {
    padding: 0;
    .group-header,
    .group-footer {
      .make-xs-column(12);
    }
    .group-left,
    .group-middle,
    .group-right {
      .make-xs-column(12);
      .make-sm-column(4);
      .img-responsive {
        width: 100%;
      }
      .field-name-field-image-left,
      .field-name-field-image-center,
      .field-name-field-image-right {
        margin-bottom: 20px;
      }
      .field-name-field-name-left,
      .field-name-field-name-center,
      .field-name-field-name-right {
        font-size: 22px;
        margin-bottom: 5px;
        font-weight: bold;
        color: #cf4960;
        @media @normal {
          font-size: 26px;
        }
      }
      .field-name-field-role-left,
      .field-name-field-role-center,
      .field-name-field-role-right {
        font-size: 24px;
        margin-bottom: 5px;
        font-weight: 400;
        line-height: 1.1;
      }
      .field-name-field-e-mail-left a,
      .field-name-field-e-mail-center a,
      .field-name-field-e-mail-right a {
        word-break: break-word;
        color: #802d77;
        font-size: 14px;
      }
      .field-name-field-description-left,
      .field-name-field-description-center,
      .field-name-field-description-right {
        font-size: 14px;
      }
    }
  }
}

.field-name-field-cards .entity.card-anchor-links {
  padding: 0;
  margin-left: -10px;
  margin-right: -10px;
  background-color: #fff;
  .card-wrapper {
  }
  .field-name-anchor-link {
    margin-bottom: 0;
    ul {
      .make-row();
      padding: 0;
      list-style: none;
      margin-top: 10px;
      margin-bottom: 0;
    }
    li {
      .make-xs-column(12);
      .make-sm-column(4);
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 10px;
      }
      @media @tablet {
        margin-bottom: 10px;
      }
      a {
        width: 100%;
        min-height: 15px;
        padding-left: 45px;
        background-image: url('../../images/icon-anchor-arrow.png');
        background-repeat: no-repeat;
        background-position: 0 3px;
        background-size: 28px 15px;
        display: inline-block;
      }
    }
  }
}

.field-name-field-cards .entity.card-three-column-v2 {
  background-color: #fff;
  padding: 40px 0 30px;
  margin: 20px 0 30px;
  .make-row();
  .card-wrapper {
    padding: 0;
    text-align: center;
    .group-left,
    .group-middle,
    .group-right {
      .make-xs-column(12);
      .make-sm-column(4);
    }
    .group-left,
    .group-middle {
      margin-bottom: 40px;
      @media @tablet {
        margin-bottom: 0;
      }
    }
    .field-name-field-title-left,
    .field-name-field-title-center,
    .field-name-field-title-right {
      font-size: 20px;
      color: #cf4960;
      font-weight: bold;
    }
    .field-name-field-description-left,
    .field-name-field-description-center,
    .field-name-field-description-right {
      font-size: 14px;
    }
    .field-name-field-read-more-left,
    .field-name-field-read-more-center,
    .field-name-field-read-more-right {
      margin-bottom: 0;
      a {
        .btn;
        .btn-default;
        margin-bottom: 0;
        margin-right: 0;
        background-color: #933c86;
        color: #fff;
        font-size: 14px;
        border-color: #933c86;
        padding: 8px 28px 6px;
        &:hover {
          background-color: #b249a3;
          border-color: #b249a3;
          color: #fff;
        }
        &:active, &:focus {
          background-color: #803475;
          border-color: #803475;
          color: #fff;
        }
      }
    }
  }
}

.node-investor {
  & > .group-left {
    .make-sm-column(4);
  }
  & > .group-right {
    .make-sm-column(8);
  }
}

.field-name-field-cards .entity.card-two-column-video,
.field-name-field-cards .entity.card-two-column {
  .make-row();
  .card-wrapper {
    @media @normal {
      padding: 30px 0;
    }
    .group-left,
    .group-middle,
    .group-right {
      .make-xs-column(12);
      padding: 20px 15px;
      @media @normal {
        padding: 0;
      }
    }
    .group-left {
      .make-sm-column(7);
      @media @normal {
        padding-right: 20px;
      }
    }
    .group-right {
      .make-sm-column(5);
      @media @normal {
        padding-left: 20px;
      }
    }
    .field-name-title {
      padding: 0;
    }

    .field-name-field-link {
      .button-cta-solid();
      &:hover {
        background: @sib-secondary;
      }
    }
  }
  .field-name-title h2 {
    margin-top: 0;
  }

  &.swap-columns {
    .card-wrapper {
      .group-right {
        .make-sm-column(7);
        @media @normal {
          padding-right: 20px;
        }
      }
      .group-left {
        .make-sm-column(5);
        @media @normal {
          padding-left: 20px;
        }
      }
    }
  }
}

.field-name-field-cards .entity.card-listing-card {
  &.bg-grey {
    background-color: #f8f8f6;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 40px 0 30px;
  }
  .card-wrapper {

    .group-left,
    .group-right {
      .make-xs-column(12);
      .make-sm-column(6);
    }
    @media @normal {
      padding: 0 0;
    }
    .view-footer a {
      color: @text-color;
      border-bottom: solid 2px #cf4960;
      font-weight: bold;
      text-decoration: none;
      &:hover {
        border-bottom: solid 2px @text-color;
      }
    }
  }
  #block-views-latest-listing-blocks-block h2.block-title {
    background-image: url('../../images/icon-news-home.png');
  }
  #block-views-latest-listing-blocks-block-1 h2.block-title {
    background-image: url('../../images/icon-events-home.png');
  }
  #block-views-latest-listing-blocks-block,
  #block-views-latest-listing-blocks-block-1 {
    h2.block-title {
      padding-left: 64px;
      background-size: 48px 54px;
      background-position: 0 0;
      background-repeat: no-repeat;
      line-height: 54px;
    }
  }
}

.field-name-field-cards .entity.card-home-banner {
  .card-wrapper {
    padding: 20px 0 0;
    @media @normal {
      padding: 0;
    }
    ul.menu {
      margin-left: -10px;
      margin-right: -10px;
      @media @normal {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .menu > li {
      padding-right: 10px;
      padding-left: 45px;
      font-family: @font-family-headers;
      background-image: url('../../images/icon-home-arrow.png');
      background-repeat: no-repeat;
      background-position: 10px 5px;
      background-size: 15px 27px;
      font-size: 16px;
      @media @normal {
        font-size: 18px;
      }
      a {
        color: #802d77;
        border-bottom: solid 2.5px #11addb;
        background-color: transparent;
        display: inline-block;
        padding: 6px 0 0;
        &:hover {
          border-bottom: solid 2.5px @text-color;
        }
      }
      ul {
        display: none;
      }
    }
  }
}

.ds-3col-stacked-equal {
  .make-row();
}

.entity-card {
  overflow: hidden;
  &.white-background .card-wrapper {
    background-color: #fff;
    @media @normal {
      padding: 80px 0;
    }
  }
}

.card-one-column {
  .make-row();
  .field-name-field-body {
    .field-item > h2 {
      text-align: center;
      font-size: 33px;
    }
    a.btn.btn-default {
      margin-bottom: 0;
      margin-right: 0;
      margin-top: 10px;
      padding-left: 40px;
      padding-right: 40px;
      background-color: #802d77;
      color: #fff;
      font-size: 14px;
      border-color: #802d77;
      &:hover {
        background-color: #a13896;
        border-color: #a13896;
        color: #fff;
      }
      &:active, &:focus {
        background-color: #74276c;
        border-color: #74276c;
        color: #fff;
      }
    }
  }

  .card-wrapper .field-name-field-body .partner-row {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    @media @normal {
      width: 65%;
      margin: auto;
    }
    .partner-item {
      width: 50%;
      @media @tablet {
        width: 20%;
      }
    }
  }
}

.field-name-field-cards .field-items > .field-item {
  z-index: 1;
  position: relative;
}

.field-name-field-cards .field-name-title h2 {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.72px;
  line-height: 28px;
  @media @wide {
    font-size: 33px;
    line-height: 43px;
  }
}

.field-name-field-youtube {
  margin: 0;
}

.node-type-landing-page {
  .page-info {
    @media @normal {
      padding: 0 10px;
    }
  }
}

.isotope-options {
  padding: 0;
  list-style: none;
  margin-left: -10px;
  margin-right: -10px;
  @media @tablet {
    margin-left: 0px;
    margin-right: 0px;
  }
  li {
    display: inline-block;
    float: left;
    &:first-child a {
      border-radius: 5px 0 0 5px;
    }
    &:last-child a {
      border-radius: 0 5px 5px 0;
      border: 0;
    }
  }
  a.filterbutton {
    background-color: #e3e3e3;
    color: @text-color;
    display: block;
    padding: 12px 26px;
    font-size: 13px;
    border-right: 1px solid #fff;
    .field {
      margin: 0;
    }
  }
  a.filterbutton.selected,
  a:hover {
    text-decoration: none;
    color: #fff;
    background-color: #9b9b9b;
    border-right: 1px solid #9b9b9b;
    &[data-filter=".equity"] {
      background-color: #11addb;
      border-right: 1px solid #11addb;
    }
    &[data-filter=".borrow"] {
      background-color: #802d77;
      border-right: 1px solid #802d77;
    }
    &[data-filter=".other"] {
      background-color: #cf4960;
      border-right: 1px solid #cf4960;
    }
    &[data-filter=".tool"] {
      background-color: #cf4960;
      border-right: 1px solid #cf4960;
    }
  }
}

/*
@robertoperuzzo
Workaround for issue #51: hide an unexpected <div> appears in TEST and LIVE.
<div class="field field-name-anchor-id field-type-ds field-label-hidden">
*/
.card-wrapper {
  body.front & {

  }

  overflow: hidden;
  .field-name-anchor-id {
    margin-bottom: 0;
  }
}

/*
@minh - custom-card
*/

.region-content-bottom.fancy-custom-card {

  display: flex;
  width: 100%;
  position: relative;

  @media only screen and (max-width: 991px) {
    display: block;
  }

  .group-title-number {
    background-color: #12adda;
    display: flex;
    @media only screen and (max-width: 991px) {
      display: inline-block;
      background-color: transparent;
      width: 100%;
    }

    @media @normal {
      width: 70%;
    }
    @media @wide {
      width: 80%;
    }

    .field-name-title {

      max-width: 95%;
      white-space: nowrap;

      @media only screen and (max-width: 991px) {
        min-width: 100%;
        background-color: #12adda;
      }

      .block-title {
        color: #fff;
        font-size: 24px;
        line-height: 1.38;
        text-align: left;
        padding: 44px 25px;
        font-weight: normal;
        margin: 0;
        // display: table-cell;
        overflow: hidden;
        text-overflow: ellipsis;
        @media only screen and (max-width: 991px) {
          display: block;
          text-align: center;
          white-space: normal;
        }
      }
    }
    .field-name-field-number {
      width: 90px;
      background-color: transparent;
      position: relative;
      background-image: url(../../images/pag-cta-bg.png);
      background-position: 40% 50%;
      background-repeat: no-repeat;
      z-index: 999;
      margin: 0px;
      position: absolute;
      min-height: 121px;

      @media only screen and (max-width: 991px) {
        background-image: none;
        width: 100%;
        display: block;
        position: relative;
        min-height: 40px;
      }

      @media @normal {
        right: 205px;
      }
      @media @wide {
        right: 210px;
      }

      .field-item {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        color: #fff;
        text-align: center;
        background-color: #12adda;
        line-height: 50px;
        font-size: 17px;
        border: 5px solid #fff;
        position: absolute;
        top: 25%;

        @media only screen and (max-width: 991px) {
          top: -25%;
          left: 47%;
        }

        @media @normal {
          right: 0px;
        }
        @media @wide {
          right: 4px;
        }
      }
    }
  }

  .field-name-field-link {
    background: white;
    text-align: center;
    margin: 0;
    @media only screen and (max-width: 991px) {
      min-height: 120px;
    }
    @media @normal {
      width: 30%;
    }
    @media @wide {
      width: 20%;
    }
    a {
      background-color: #933c86;
      border-color: #933c86;
      margin: 0;
      color: #fff;
      border-radius: 6px;
      padding: 10px 20px 8px;
      font-size: 14px;
      margin-left: 20px;
      margin-top: 40px;
      display: inline-block;
      width: 160px;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-height: 37px;
      overflow: hidden;
      &:hover {
        background-color: #b249a3;
        border-color: #b249a3;
        text-decoration: none;
      }
    }
  }
}

.page-node.node-type-investor {
  .field-name-field-introduction {
    font-weight: bold;
  }
}

.grey-background {
  background-color: #F5F5F5;
}

.button-cta-trans() {
  font-size: 0.7em;
  font-weight: bold;
  @media @normal {
    font-size: large;
  }

  background-color: rgba(18, 173, 218, 0.5);

  border: 3px solid white;
  border-radius: 4px;

  display: inline-block;

  a:link, a:visited, a:hover, a:active {
    line-height: 3.5em;
    color: white;
    padding: 0 50px 0 20px;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    text-decoration: none;
    @media @tablet {
      padding: 0 80px 0 20px;
    }

    &::after {
      content: '.';
      position: absolute;
      right: 0;
      width: 37px;
      background: white url(../../images/Right.png) no-repeat center;
    }
  }
}

.button-cta-solid() {
  font-size: small;
  @media @normal {
    font-size: large;
  }

  background-color: @sib-primary;
  border: none;
  border-radius: 0;
  display: inline-block;
  text-align: center;

  a:link, a:visited, a:hover, a:active {
    line-height: 3em;
    color: white;
    padding: 0 40px;
    font-weight: 700;
    position: relative;
    text-decoration: none;
  }
  &:hover {
    background: @sib-secondary;
  }
}

.button-cta-solid-reverse() {
  .button-cta-solid();
  a:link, a:visited, a:hover, a:active {
    padding: 0 40px;
  }
}


/* PREV/NEXT buttons on blogs */
.field-name-previous-node {
  width: 50%;
  float: left;
  text-align: right;
  .view-next-previous-node-pager {
    .views-row-last {
      border: none;
    }
    .views-field-title {
      .button-cta-solid();
      font-size: small !important;
      a:link, a:visited, a:hover, a:active {
        padding: 0 60px 0 20px;
      }
    }
  }
}

.field-name-next-node {
  width: 50%;
  float: left;
  .view-next-previous-node-pager {
    .views-row-last {
      border: none;
    }
    .views-field-title {
      .button-cta-solid-reverse();
      font-size: small !important;
      a:link, a:visited, a:hover, a:active {
        padding: 0 20px 0 60px;
      }
    }
  }
}

/**
 * Search page.
 */
.page-search {
  .region-content {
    &:extend(.col-md-10);
    &:extend(.col-lg-8);
    margin: 0 -15px;

    .view-filters {
      border: none;
      padding: 0;
      margin-top: 50px;

      label {
        font-weight: bold;
        font-size: 18px;
        position: absolute;
        bottom: calc(100% + 35px);
      }
    }

    .views-exposed-widget {
      float: none;
    }

    .views-exposed-widgets {
      border-radius: 5px;
      border: 3px solid #dbdbe6;
      position: relative;

      input {
        margin: 0;
        border: none;
        color: #000;
        font-weight: bold;
        padding: 0 60px 0 15px;
      }
    }

    .views-submit-button {
      position: absolute;
      right: 0px;
      top: 0px;

      button {
        border: none;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        padding: 8px 80px;
        background: @sib-primary;

        &:hover {
          background: @sib-secondary;
        }
      }
    }

    .view-header {
      margin-bottom: 15px;

      @media @tablet {
        margin-top: 30px;
      }
    }

    .views-row {
      border-bottom: 3px solid #ebe5e5;
    }

    .views-row:first-child {
      border-top: 3px solid #ebe5e5;
    }
  }
  .search-illustration {
    position: absolute;
    right: 0;
    width: 150px;
    top: -40px;
    @media @wide {
      width: 300px;
      top: -70px;
    }
  }
}
.view-search {
  @media @tablet {
    .views-row {
      padding-left: 50px;
    }
  }
  .view-filters {
    display: none;
    @media @normal {
      display: block;
    }
  }
}
.node-teaser {
  h3 {
    font-weight: bold;
    margin-bottom: 10px;
  }
}

#block-gf-basic-sibs-download-links {
  p {
    margin-top: 30px;
  }
  .btn {
    font-size: 14px;
    font-weight: bold;
    white-space: inherit;
  }
}

#block-gf-basic-sibs-download-links {
  .btn {
    margin: 0 auto 15px;
    white-space: inherit;
  }
  @media @tablet {
    .btn {
      margin: 0 0 15px;
    }
  }
}
.node-webform {
  .field-name-field-body {
    &:extend(.col-md-7);
    &:extend(.col-sm-10);
    padding: 0;
  }
}

.webform-client-form label {
  @media @tablet {
    width: 83.33333%;
  }
  @media @normal {
    width: 58.33333%;
  }
  font-weight: normal;
}
.webform-client-form {
  .form-actions {
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;

    .btn {
      border-radius: 0;
      border: none;
      background: @sib-primary;
      margin: 0 0 0 15px;
      &:hover,
      &:focus {
        background: @sib-secondary;
      }
    }
  }
  @media (max-width: 600px) {
    .form-actions {
      flex-direction: column;

      button:first-child {
        margin-bottom: 15px;
      }
    }
  }
}
.webform-component-radios {
  margin-bottom: 35px;
  > label {
    margin-bottom: 10px;
    margin-top: 30px;
    padding-left: 0;
  }
}
.webform-component-markup {
  padding: 0;
}

// WYSIWYG templates.
//.content-box {
//  padding: 30px 15px;
//  h2 {
//    margin-top: 0;
//    font-weight: 600;
//    margin-bottom: 1em;
//  }
//  &.case-study {
//    background: #fef3ea;
//  }
//  &.masterclass {
//    background: #ddf1f6;
//  }
//  &.checklist {
//    background: #f1eaf1;
//  }
//  &.box {
//    border: 1px solid #e3d6e5;
//  }
//}

.case-study {
  background-color: #FEF3EA;
  font-family: 'Montserrat', sans-serif;
  padding: 50px 30px;
  position: relative;
  img {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 40px;
  }
  h2 {
    font-size: 20px;
    font-weight: normal;
    margin: 0 0 20px;
    padding-right: 50px;
  }
}
.checklist {
  background-color: #F1EAF2;
  font-family: 'Montserrat', sans-serif;
  padding: 50px 30px 20px;
  position: relative;
  img {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
  }
  h2 {
    font-size: 36px;
    font-weight: normal;
    margin: 0 0 20px;
    padding-right: 40px;
  }
}

.further-resources {
  color: #000;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 20px;
}
.further-resources__icon {
  align-items: center;
  background: #B70050;
  display: flex;
  justify-content: center;
  width: 80px;

  img {
    max-width: 30px;
  }
}
.further-resources__content {
  background-color: #f6e1ea;
  padding: 20px;
  width: 100%;
  h2 {
    color: #000;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  a {
    color: #000;
    text-decoration: underline;
  }
  a:hover,
  a:focus {
    color: #6e2a74;
    text-decoration: underline;
  }
}
.further-resources__content ul li::before {
  background-color: #AB78AA !important;
}
.list-icons {
  font-family: 'Montserrat', sans-serif;
  padding-left: 0;
  li {
    list-style: none;
    margin-bottom: 40px;
    position: relative;
  }
  li:before {
    display: none;
  }
  li div {
    margin-left: 60px;
  }
  svg {
    left: 0;
    position: absolute;
    top: 0;
  }
  h2 {
    font-size: 20px;
    font-weight: normal;
    margin: 0 0 16px;
  }
}
@media only screen and (min-width: 500px) {
  .list-icons li div {
    margin-left: 80px;
  }
}

.masterclass {
  background-color: #DDF2F6;
  font-family: 'Montserrat', sans-serif;
  padding: 50px 30px;
  position: relative;
  img {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 41px;
  }
  h2 {
    font-size: 20px;
    font-weight: normal;
    margin: 0 0 10px;
    padding-right: 50px;
  }
  h3 {
    color: #434040;
    font-size: 14px;
    margin: 0 0 30px;
  }
  ul {
    padding-left: 0;
  }
}

.masterclass ul li {
  font-family: 'Montserrat', sans-serif;
  list-style: none;
  margin: 0 0 20px 20px;
  position: relative;
}
.masterclass > ul > li {
  padding-left: 0!important;
}
.masterclass ul li::before {
  background-color: #A17DAE;
  border-radius: 50%;
  font-size: 20px;
  content: "";
  color: #a16da0;
  display: inline-block;
  height: 9px;
  margin-left: -20px;
  position: absolute;
  top: 5px;
  width: 9px;
}
.masterclass .sub-numbers li {
  counter-increment: list;
  list-style: none;
  margin-bottom: 16px;
  padding-left: 20px;
  position: relative;
}

.masterclass .sub-numbers li:before {
  background-color: transparent;
  border-color: transparent;
  color: #79307F;
  content: counter(list);
  font-size: 18px;
  font-weight: bold;
  left: 0;
  position: absolute;
  top: -3px;
  width: 26px;
}
.masterclass ul li .sub li::before {
  background-color: transparent;
  border: 2px solid #A17DAE;
  content: "";
  top: 5px;
  width: 9px;
}
.masterclass ul li .sub,
.masterclass ul li .sub-numbers {
  margin-top: 20px;
}

.tools {
  background: #DDF2F6;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 20px;
}

.tools h2 {
  background: #87D1DE;
  color: #000000;
  display: block;
  font-size: 20px;
  margin: 0;
  padding: 20px 30px;
}

.tools h3 {
  margin: 0;
}

@media only screen and (min-width: 500px) {
  .tools h3 {
    padding-right: 35px;
  }
}

.tools__content {
  padding: 20px 10px;
  position: relative;
}

@media only screen and (min-width: 500px) {
  .tools__content {
    padding: 30px;
  }
}

.tools .smaller {
  font-size: 14px;
}

.tools__content ul {
  margin-bottom: 30px;
  padding-left: 16px;
}

.tools__content li {
  counter-increment: list;
  list-style: none;
  margin-bottom: 16px;
  padding-left: 20px;
  position: relative;
}

.tools__content li:before {
  color: #79307F;
  content: counter(list);
  font-size: 18px;
  font-weight: bold;
  left: -16px;
  position: absolute;
  width: 26px;
}

.tools__content a.btn-download {
  background-color: #B70050;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: bold;
  padding: 20px 15px;
  margin: 16px 0;
  text-align: center;
}

.tools__content a.btn-download span {
  background-image: url(img/icon-download.svg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 25px 20px;
  height: 20px;
  padding-right: 33px;
  width: 25px;
}

@media only screen and (min-width: 500px) {
  .tools__content a.btn-download {
    display: inline-block;
    padding: 20px 85px;
  }
}

.tools__content a.btn-download:focus,
.tools__content a.btn-download:hover {
  background-color: #810038;
  text-decoration: none;
}

.tools__icon {
  display: none;
  position: absolute;
  right: 30px;
}

@media only screen and (min-width: 500px) {
  .tools__icon {
    display: block;
  }
}

.tools .tools__content:before {
  background-color: #53BED0;
  content: "";
  height: 1px;
  position: absolute;
  top: 0;
  left: 30px;
  right: 30px;
}

.tools .tools__content:first-of-type:before {
  display: none;
}
.quote {
  background: #F7E5ED;
  border: 1px solid #E4D6E5;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 50px;
  padding: 40px 69px 35px 35px;
  position: relative;
}

.quote:after {
  background: #F7E5ED;
  border-right: 1px solid #E4D6E5;
  border-bottom: 1px solid #E4D6E5;
  bottom: -21px;
  content: "";
  display: block;
  height: 40px;
  position: absolute;
  right: 30px;
  transform: rotate(45deg);
  width: 40px;
}

.quote p {
  color: #79307F;
  display: block;
  font-style: normal;
}

.quote__info:before,
.quote__info:after,
.quote__text:before,
.quote__text:after {
  display: none;
}

.quote__info {
  font-size: 16px;
  padding-right: 40px;
  text-decoration: underline;
}

.quote__info a {
  color: #79307F;
  text-decoration: underline;
}

.quote__info a:hover {
  color: #79307F;
  text-decoration: underline;
}

.quote__text {
  font-size: 20px;
}

.quote__author {
  font-size: 14px;
  margin-left: 5px;
}

.quote__icon {
  position: absolute;
  right: 35px;
  top: 35px;
  width: 30px;
}
.table {
  font-family: 'Montserrat', sans-serif;
}

.table td,
.table th {
  padding: 13px !important;
}

.table thead th {
  border-bottom-width: 1px !important;
}

.table thead {
  background-color: #DDF2F6;
}

.table caption {
  color: #000;
  text-align: left;
}

.visually-hidden { /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.box {
  border: 1px solid #E4D6E5;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 50px;
  padding: 40px 69px 35px 35px;
  position: relative;
}

.box p, .box h2 {
  color: #000;
  display: block;
  font-size: 16px;
  font-style: normal;
}
.box h2 {
  font-size: 20px;
  margin-top: 0;
}
.box ul {
  padding-left: 0;
  margin-top: 3rem;
}
.box ul li {
  font-family: 'Montserrat', sans-serif;
  list-style: none;
  margin: 0 0 20px 20px;
  position: relative;
}
.box ul li::before {
  background-color: #A17DAE;
  border-radius: 50%;
  font-size: 20px;
  content: "";
  color: #a16da0;
  display: inline-block;
  height: 9px;
  margin-left: -20px;
  position: absolute;
  top: 5px;
  width: 9px;
}