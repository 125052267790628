/**
 * Layout
 * Major components eg Header,
  *footer and sidebar
 */

/**
 * Page Header
 */
#page-header {
  padding: 10px 0;
  height: 58px;
  @media @normal {
    padding: 40px 0 10px;
    height: 105px;
    -webkit-transition-duration: 300ms;
    -moz-transition-duration: 300ms;
    -o-transition-duration: 300ms;
    transition-duration: 300ms;
  }
  &.shrink {
    @media @normal {
      padding-top: 10px;
      height: 65px
    }
  }
  .no-padding {
    @media @normal {
      padding: 0;
    }
  }
}

.front {
  #header-wrapper,
  .card-hero-image,
  .page-info-wrapper {
    background-color: #ddf2f6;
  }
}

.front #page-header {
  border-bottom: 1px solid #d4d4d4;
  @media @normal {
    border-bottom: 0;
    padding-top: 35px;
    height: 95px;
  }
}

#navbar {
  @media @normal {
    height: 40px;
  }
}

/**
 * Content top
 */
#content-top {
  margin-bottom: 20px;
}

.node-type-landing-page h1.page-header {
  display: none !important;
}

.not-front {
  header, #header-wrapper {
    background-color: @body-bg;
  }
  #page-header {
    @media @normal {
      border-bottom: none;
    }
  }
  .main-container {
    margin-top: 58px;
    @media @normal {
      margin-top: 130px;
    }
  }
}
.not-front #page-header {
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;

  .row {
    display: flex;
    height: 100%;
    align-items: center;
  }
  .navbar-btn {
    margin: 0;
  }
}
.navbar-tray-open.navbar-fixed.navbar-horizontal.not-front {
  #page-header {
    top: 79px;
  }
}
#sidr-main-menu-content {
  position: fixed;
  top: 0px;
  background: @sib-primary;
  padding: 58px 10px 10px;
  height: calc(100vh);
  z-index: 100;

  @media @normal {
    display: none !important;
  }
  
  a {
    color: #fff;
    text-align: left;
    padding: 20px 0px;
    border-bottom: 2px solid #a16da5;
    &.active-trail {
      font-weight: bold;
    }
    &:hover {
      background: transparent;
    }
  }
  ul ul {
    padding-left: 15px;
    display: none;
  }
  ul ul a {
    padding: 10px 0px;
    border: none;
  }

  li.active-trail {
    ul {
      display: block;
    }
  }
}
.main-container {
}

/**
 * Content bottom
 */
#content-bottom {
  margin-top: 30px;
}

/**
 * Footer
 */
#footerwrap {
  z-index: 1001;
  margin-top: 50px;
  color: #fff;
  
  .footer-top {
    color: #666;
    padding: 20px 0;
    background: #fff;
    .footer-content {
      margin: 0;
    }
  }

  a {
    color: #fff;
  }
  .footer {
    background-color: #12adda;
    border: 0;
  }
  .footer-content {
    margin-top: 30px;
    border-top: 2px solid #ccc;
    padding-top: 30px;
  }
}
.front #footerwrap .footer-content {
  border-top: none;
}
.footer-logo {
  margin-top: 30px;
  justify-content: center;
  margin-bottom: 20px;
  display: block;

  img.tf {
    height: 45px;
    margin-bottom: 20px;
  }

  img.dcms,
  img.ml {
    height: auto;
    float: left;
  }
  img.dcms {
    max-width: 150px;
    width: 30%;
    margin-right: 10%;
  }
  img.ml {
    width: 55%;
    max-width: 250px;
  }

  @media @tablet {
    display: flex;
    justify-content: initial;
    margin-bottom: 0px;

    img {
      display: inline-block;
    }
    img.tf {
      height: 45px;
      margin-top: 25px;
      margin-bottom: 0;
      width: auto;
    }
    img.dcms {
      height: 80px;
      margin-right: 50px;
      width: auto;
    }
    img.ml {
      height: 90px;
    }
  }
}

/** Stick footer **/
#sb-site, body {
  flex-direction: column;
  min-height: 100vh;
}

#footerwrap {
  margin-top: auto;
}

.card-wrapper,
.container-fluid,
.page-info,
.node-landing-page .field-name-field-introduction,
#block-views-product-types-block,
#block-views-other-funding-options-block,
.region-content-bottom {
  .container;
  @media @normal {
    max-width: 784px;
  }
  @media @wide {
    max-width: @screen-lg;
  }
}

.region-content-bottom,
#block-views-other-funding-options-block {
  padding-left: 0;
  padding-right: 0;
  @media @normal {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#block-views-product-types-block {
  @media @tablet {
    padding-left: 0;
    padding-right: 0;
  }
  @media @normal {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.page-info {
  padding-left: 0;
  padding-right: 0;
}

.node-type-landing-page {
  .main-container .container-fluid {
    max-width: none;
  }
}

#page-header .region-header {
  display: flex;
  justify-content: flex-end;
  .block {
    display: block;
  }
}

#page-header,
.search-exposed-form-mobile {
  #views-exposed-form-search-search-page {
    float: right;
    ::placeholder {
      color: white;
    }
    input {
      margin-bottom: 0;
    }
    .views-exposed-form {
      position: relative;
    }
    .views-exposed-form .views-exposed-widget {
      padding: 0;
      @media @tablet {
        max-width: none;
      }
    }
    .form-control {
      background-color: #FFF;
      &::placeholder {
        color: lighten(@text-color, 40%);
      }
      &::selection {
        //background-color: white;
      }
      border: 2px solid lighten(@text-color, 60%);
      border-radius: 5px;
      letter-spacing: -0.46px;
      line-height: 22px;
      padding: 4px 12px 3px 35px;
      font-size: 14px;
      color: #000;
      @media @tablet {
        min-width: 0;
        width: 210px;
      }
      @media @wide {
        width: 215px;
      }
    }
    label {
      display: none;
    }
    .views-submit-button {
      padding-right: 0;
    }
    .views-submit-button button {
      position: absolute;
      border: 0;
      background: url('../../images/icons/search.svg') no-repeat center;
      text-indent: -90000px;
      padding: 0;
      display: block;
      border-radius: 0 6px 6px 0;
      margin: 0;
      left: 0;
      top: 0;
      background-size: 40%;
      width: 35px;
      height: 35px;
    }

  }
}

.search-exposed-form-mobile {
  padding-bottom: 30px;
  padding-top: 15px;
  #views-exposed-form-search-search-page {
    float: none;
    .form-control {
      width: 100%;
    }
    .views-exposed-widget {
      float: none;
      max-width: 100%;
    }
  }
}
.front .search-exposed-form-mobile {
  background: #def2f6;
}
.footer {
  font-size: 16px;
  @media @tablet {
    text-align: left;
    #block-block-16 {
      float: left;
    }
  }
  .region-footer {
    padding-top: 20px;
    .make-row();
    margin-bottom: 30px;
    .block {
      .make-sm-column(6);
      margin-bottom: 15px;
      @media @tablet {
        float: right;
        width: auto;
        min-width: 150px;
        margin-bottom: 0px;
        ul li {
          margin-bottom: 0;
        }
      }
      h2.block-title {
        color: #fff;
        font-size: 16px;
        font-weight: normal;
        margin-top: 5px;
      }
      p {
        font-size: 16px;
        margin: 0 0 2px;
      }
    }
    .social-footer {
      min-width: 0;
      @media @tablet {
        padding-right: 10px;
      }
      ul {
        text-align: center;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: inline-block;
          margin: 0 5px;
          width: 30px;
          @media @tablet {
            display: block;
            float: left;
            margin-bottom: 10px;
            &:nth-child(2n+1) {
              clear: left;
            }
          }
          a {
            text-indent: -90000px;
            display: block;
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: 30px 30px;
          }
          &.twitter a {
            background-image: url('../../images/social/twitter.svg');
          }
          &.facebook a {
            background-image: url('../../images/social/facebook.svg');
          }
          &.linkedin a {
            background-image: url('../../images/social/linkedin.svg');
          }
          &.youtube a {
            background-image: url('../../images/social/youtube.svg');
          }
          &.instagram a {
            background-image: url('../../images/social/instagram.png');
          }
        }
      }
    }
  }
  .region-footer-second {
    .make-row();
    #block-menu-block-4 {
      .make-sm-column(12);
    }
    ul.menu {
      text-align: center;
      @media @tablet {
        float: right;
      }
      li {
        font-size: 16px;
        margin: 0 15px;
        display: inline-block;
        @media @tablet {
          display: block;
          float: left;
          margin: 0 0 0 30px;
        }
      }
    }
  }
  .nav > li > a {
    padding: 5px 0;
    &:hover, &:focus {
      background-color: transparent;
      text-decoration: underline;
    }
  }
  #block-block-16 {
    h3 {
      color: #fff;
      font-weight: bold;
    }
    .make-sm-column(4);
    margin-bottom: 20px;
    @media @normal {
      padding: 0;
    }
    img {
      width: 133px;
      display: inline-block;
    }

  }
}

.well {
  min-height: 20px;
  padding: 0;
  margin-bottom: 20px;
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  box-shadow: inset 0;
}

.region-cta-bottom {
  background-color: #933c86;
  padding: 40px 0;
  .block {
    .container-fluid;
    p {
      color: #fff;
      font-size: 20px;
      text-align: center;
      @media @tablet {
        font-size: 24px;
      }
      a {
        color: #fff;
        border-bottom: solid 2.5px #2dbded;
        &:hover, &:focus {
          text-decoration: none;
          border-bottom: solid 2.5px #fff;
        }
      }
    }
  }
}

