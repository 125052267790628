.card-listing-card {
  background-color: #EAEAEA;
  margin-left: -15px;
  margin-right: -15px;
  overflow: visible !important;

  .card-wrapper {
    overflow: visible !important;
  }

  .field-name-field-left-listing .view-latest-listing-blocks {

    .view-content {
      .make-row();
    }
    .views-row {
      .make-xs-column(12);
      .make-sm-column(4);

      border: 0;
      @media @normal {
        margin-top: -65px;
      }

      .node {
        background-color: white;
        padding-bottom: 10px;
      }
    }

    .node {
      .field-name-title {
        background-color: #12ADDA;
        letter-spacing: 0.14px;
        line-height: 42px;
        h3 {
          font-size: 18px;
          letter-spacing: 0.14px;
          line-height: 30px;
          margin: 10px;
          @media @wide {
            font-size: 28px;
            letter-spacing: 0.14px;
            line-height: 42px;
          }
        }
        a:link, a:visited, a:hover, a:active {
          color: white;
          font-weight: 900;
        }

        .field-items {
          padding: 10px;
          @media @normal {
            //height: 10em;
          }
        }

        &::before {
          content: ' ';
          display: block;
          height: 65px;
        }
      }
      .field-name-post-date {
        color: #919191;
        margin: 10px;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.06px;
        line-height: 33px;
        padding: 0 10px;
      }
      .field-name-field-introduction {
        color: #024F66;
        margin: 10px;
        font-weight: 500;
        font-family: @font-family-sans-serif;
        font-size: 18px;
        letter-spacing: 0.09px;
        line-height: 33px;
        padding: 0 10px;
        @media @normal {
          //height: 7em;
        }
      }
      .field-name-node-link {
        margin: 10px;
        padding: 0 10px;
        a {
          border: 0;
          color: #802D77;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: -0.44px;
          line-height: 24px;
          position: relative;
          padding-right: 20px;
          &::after {
            content: "";
            position: absolute;
            right: 0;
            width: 15px;
            background: url(../../images/chevron-right.png) no-repeat center center;
            height: 28px;
          }
        }
      }
    }

    .node.post-type-events .field-name-title::before {
      content: 'Good Finance Events';
      padding-left: 75px;
      color: white;
      line-height: 65px;
      font-size: 15px;
      letter-spacing: 0.07px;
      vertical-align: center;
      background: #12ADDA url(../../images/events.png) no-repeat left center;
      font-weight: 500;
    }
    .node.post-type-blog .field-name-title::before {
      content: 'Good Finance Blog';
      padding-left: 75px;
      color: white;
      line-height: 65px;
      font-size: 15px;
      letter-spacing: 0.07px;
      vertical-align: center;
      background: #12ADDA url(../../images/news.png) no-repeat left center;
      font-weight: 500;
    }
  }
}
