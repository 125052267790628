// Bootstrap variables.
@import (reference) 'variables.less';

// Media query variables.
@tablet:  ~"screen and (min-width: @{screen-sm-min})";
@normal:  ~"screen and (min-width: @{screen-md-min})";
@wide:    ~"screen and (min-width: @{screen-lg-min})";

// Drop shadows.
.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow;
     -moz-box-shadow: @shadow;
          box-shadow: @shadow;
}

html {
  &.overlay-open .navbar-fixed-top {
    z-index: 400;
  }
  &.js {
    // Collapsible fieldsets.
    fieldset.collapsed {
     height: auto;
    }
    // Throbbers.
    input.form-autocomplete {
      background-image: none;
    }
  }
}
body {
  // Fix horizontal scrolling on iOS devices.
  // http://drupal.org/node/1870076
  position: relative;

  &.admin-expanded.admin-vertical.admin-nw .navbar,
  &.admin-expanded.admin-vertical.admin-sw .navbar {
    margin-left: 260px;
  }
  // Add padding to body if various toolbar or navbars are fixed on top.
  &.navbar-is-fixed-top {
    padding-top: 64px !important;
  }
  &.navbar-is-fixed-bottom {
    padding-bottom: 64px !important;
  }
  &.toolbar {
    padding-top: 30px !important;
    .navbar-fixed-top {
      top: 30px;
    }
    &.navbar-is-fixed-top {
      padding-top: 94px !important;
    }
  }
  &.toolbar-drawer {
    padding-top: 64px !important;
    .navbar-fixed-top {
      top: 64px;
    }
    &.navbar-is-fixed-top {
      padding-top: 128px !important;
    }
  }
  // Admin_menu toolbar.
  &.admin-menu {
    .navbar-fixed-top {
      top: 29px;
    }
    &.navbar-is-fixed-top {
      padding-top: 93px !important;
    }
  }
  div#admin-toolbar {
    z-index: 1600;
  }
  // Override box-shadow styles on all "admin" menus.
  #toolbar, #admin-menu, #admin-toolbar {
    .box-shadow(none);
  }
  // Override #admin-menu style.
  #admin-menu {
    margin: 0;
    padding: 0;
    position: fixed;
    z-index: 1600;
    .dropdown li {
      line-height: normal;
    }
  }
}

// Default navbar.
.navbar.container {
  @media @tablet {
    max-width: ((@container-sm - 20px));
  }
  @media @normal {
    max-width: ((@container-md - 20px));
  }
  @media @wide {
    max-width: ((@container-lg - 20px));
  }
  margin-top: 20px;
  > .container {
    margin: 0;
    padding: 0;
    width: auto;
  }
}

// Adjust z-index of core components.
#overlay-container,
.overlay-modal-background,
.overlay-element {
  z-index: 1500;
}
#toolbar {
  z-index: 1600;
}
// Adjust z-index of bootstrap modals
.modal {
  z-index: 1620;
  button.close{
    text-indent: -9000px;
    display: block;
    width: 16px;
    height: 19px;
    background-image: url('../../images/icon-close.png');
    background-size: 16px 18px;
    background-repeat: no-repeat;
    background-position: 0 0;
    opacity: 1;
    float: none;
    position: absolute;
    top: 22px;
    right: 45px;
    &:hover{
      opacity: 0.6;
    }
  }
  .modal-content{
    padding: 40px 40px 70px;
    position: relative;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  	border: solid 1px #d7d7d7;
    margin-bottom: 60px;
    @media @tablet{
      margin-bottom: 0;
      padding-bottom: 25px;
    }
  }
  .modal-header{
    padding: 0 0 20px;
    border: 0;
    h4{
      font-size: 16px;
    }
  }
  .modal-intro{
    padding: 0 0 20px;
    font-size: 16px;
  }
  .modal-body{
    padding: 0 0 20px;
    font-size: 14px;
  }
  .modal-footer{
    padding: 0 0 50px;
    border: 0;
    text-align: center;
    @media @tablet{
      padding-bottom: 0;
    }
    .btn{
      margin-bottom: 15px;
      margin-right: 0;
      background-color: transparent;
      color: #11addb;
      font-size: 14px;
      border-color: #11addb;
      box-sizing: border-box;
      @media @tablet{
        margin-bottom: 0;
      }
      &:hover{
        background-color: transparent;
        box-shadow: 0px 0px 0px 1px #11addb inset;
        color: #11addb;
      }
      &:active,&:focus{
        background-color: #11addb;
        border-color: #11addb;
        color: #fff;
      }
    }
    .btn-solid{
      background-color: #2aa3d4;
      border-color: #2aa3d4;
      color: #fff;
      margin-bottom: 0px;
      &:hover,&:active,&:focus{
        background-color: #2fb4ea;
        border-color: #2fb4ea;
        color: #fff;
      }
    }
  }
}
.modal-dialog {
  z-index: 1630;
  width: auto;
  @media @tablet{
    width: 728px;
  }
  @media @normal{
    width: 728px;
  }
  @media @wide{
    width: 955px;
  }
}
.modal .modal-dialog {
  top: 0;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
}
.modal-backdrop {
  z-index: 1610;
  background-color: #d8d8d8;
}
.modal-backdrop.in{
  opacity: 0.75;
}
.footer {
  margin-top: 0;
  padding-bottom: 20px;
  border-top: 1px solid #E5E5E5;
  background:@gray-lighter;
}
// Element invisible fix
.element-invisible {
  margin: 0;
  padding: 0;
  width: 1px;
}

// Branding.
.navbar .logo {
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  @media @tablet {
    margin-right: 0;
    padding-left: 0;
  }
}

// Navigation.
ul.secondary {
  float: left;
  @media @tablet {
    float: right;
  }
}

// Page header.
.page-header {
  border-bottom:0;
  padding:0;
  margin: 24px 0 25px;
  @media @tablet{
    //margin: 44px 0 25px;
  }
}

// Blocks
.block {
  &:first-child h2.block-title {
    margin-top: 0;
  }
}

// Paragraphs.
p:last-child {
  margin-bottom: 0;
}

// Help region.
.region-help {
  > .glyphicon {
    font-size: @font-size-large;
    float: left;
    margin: -0.05em 0.5em 0 0;
  }
  .block {
    overflow: hidden;
  }
}

// Search form.
form#search-block-form {
  margin: 0;
}
.navbar #block-search-form {
  float: none;
  margin: 5px 0 5px 5px;
  @media @normal {
    float: right;
  }
}
.navbar-search .control-group {
  margin-bottom:0px;
}

// Action Links
ul.action-links {
  margin: @padding-base-horizontal 0;
  padding: 0;
  li {
    display: inline;
    margin: 0;
    padding: 0 @padding-base-vertical 0 0;
  }
  .glyphicon {
    padding-right: 0.5em;
  }
}

// Form elements.
input, textarea, select, .uneditable-input {
  max-width: 100%;
  width: auto;
}
input.error {
  color: @state-danger-text;
  border-color: @state-danger-border;
}
fieldset legend.panel-heading {
  float: left;
  line-height: 1em;
  margin: 0;
}
fieldset .panel-body {
  clear: both;
}
fieldset .panel-heading a.panel-title {
  color: inherit;
  display: block;
  margin: -10px -15px;
  padding: 10px 15px;
  &:hover {
    text-decoration: none;
  }
}
.form-actions{
  clear: both;
}
.resizable-textarea textarea {
  border-radius: @border-radius-base @border-radius-base 0 0;
}
.radio, .checkbox {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.help-block, .control-group .help-inline {
  color: @gray-light;
  font-size: 12px;
  margin: 5px 0 10px;
  padding: 0;
}
.panel-heading {
  display: block;
}
a.tabledrag-handle .handle {
  height: auto;
  width: auto;
}

// Error containers.
.error {
  color: @state-danger-text;
}
div.error,
table tr.error {
  background-color: @state-danger-bg;
  color: @state-danger-text;
}
.control-group.error {
  background: none;
  label, .control-label {
    color: @state-danger-text;
    font-weight: 600;
  }
  input, textarea, select, .uneditable-input {
    color: @input-color;
    border: 1px solid @input-border;
  }
  .help-block, .help-inline {
    color: @text-color;
  }
}

// Lists
.list-inline {
  > li.first {
    padding-left: 0;
  }
}
.nav-tabs {
  margin-bottom: 10px;
}
ul li.collapsed,
ul li.expanded,
ul li.leaf {
  list-style: none;
  list-style-image: none;
}
.tabs--secondary {
  margin: 0 0 10px;
}

// Submitted
.submitted {
  margin-bottom: 1em;
  font-style: italic;
  font-weight: normal;
  color: #777;
}

// Password strength indicator.
.password-strength {
  width: 17em;
  float: right;  /* LTR */
  margin-top: 1.4em;
}
.password-strength-title {
  display: inline;
}
.password-strength-text {
  float: right; /* LTR */
  font-weight: bold;
}
.password-indicator {
  background-color: darken(@input-color-placeholder, 2%);
  height: 0.3em;
  width: 100%;
  div {
    height: 100%;
    width: 0%;
    background-color: @input-color-placeholder;
  }
}
input.password-confirm,
input.password-field {
  width: 16em;
  margin-bottom: 0.4em;
}
div.password-confirm {
  float: right;  /* LTR */
  margin-top: 1.5em;
  visibility: hidden;
  width: 17em;
}
div.form-item div.password-suggestions {
  padding: 0.2em 0.5em;
  margin: 0.7em 0;
  width: 38.5em;
  border: 1px solid #B4B4B4;
}
div.password-suggestions ul {
  margin-bottom: 0;
}
.confirm-parent,
.password-parent {
  clear: left; /* LTR */
  margin: 0;
  width: 36.3em;
}

// Progress bar.
.progress-wrapper .progress {
  margin-bottom: 10px;
}

// Views AJAX pager.
.pagination ul > li > a {
  &.progress-disabled {
    float: left;
  }
}

// Autocomplete and throbber
.form-autocomplete {
  .glyphicon {
    color: @gray-light;
    font-size: 120%;
    &.glyphicon-spin {
      color: @brand-primary;
    }
  }
  .input-group-addon {
    background-color: rgb(255, 255, 255);
  }
}

// AJAX "Throbber".
.ajax-progress {
  .glyphicon {
    font-size: 90%;
    margin: 0 -.25em 0 0.5em;
  }
}

.glyphicon-spin {
  display: inline-block;
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}
a .glyphicon-spin {
  display: inline-block;
  text-decoration: none;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.glyphicon-refresh {
  -webkit-transform-origin: 50% 45%;
  -moz-transform-origin: 50% 45%;
  -ms-transform-origin: 50% 45%;
  -o-transform-origin: 50% 45%;
  transform-origin: 50% 45%;
}

/**
 * Missing Bootstrap 2 tab styling.
 * @see http://stackoverflow.com/questions/18432577/stacked-tabs-in-bootstrap-3
 * @see http://bootply.com/74926
 */
.tabbable {
  margin-bottom: 20px;
}
.tabs-below, .tabs-left, .tabs-right {
  > .nav-tabs {
    border-bottom: 0;
    .summary {
      color: @nav-disabled-link-color;
      font-size: @font-size-small;
    }
  }
}
.tab-pane > .panel-heading {
  display: none;
}
.tab-content > .active {
  display: block;
}

// Below.
.tabs-below {
  > .nav-tabs {
    border-top: 1px solid @nav-tabs-border-color;
    > li {
      margin-top: -1px;
      margin-bottom: 0;
      > a {
        border-radius: 0 0 @border-radius-base @border-radius-base;
        &:hover,
        &:focus {
          border-top-color: @nav-tabs-border-color;
          border-bottom-color: transparent;
        }
      }
    }
    > .active {
      > a,
      > a:hover,
      > a:focus {
        border-color: transparent @nav-tabs-border-color @nav-tabs-border-color @nav-tabs-border-color;
      }
    }
  }
}

// Left and right tabs.
.tabs-left,
.tabs-right {
  > .nav-tabs {
    padding-bottom: 20px;
    width: 220px;
    > li {
      float: none;
      &:focus {
        outline: 0;
      }
      > a {
        margin-right: 0;
        margin-bottom: 3px;
        &:focus {
          outline: 0;
        }
      }
    }
  }
  > .tab-content {
    border-radius: 0 @border-radius-base @border-radius-base @border-radius-base;
    .box-shadow(0 1px 1px rgba(0,0,0,.05));
    border: 1px solid @nav-tabs-border-color;
    overflow: hidden;
    padding: 10px 15px;
  }
}

// Left tabs.
.tabs-left {
  > .nav-tabs {
    float: left;
    margin-right: -1px;
    > li > a {
      border-radius: @border-radius-base 0 0 @border-radius-base;
      &:hover,
      &:focus {
        border-color: @nav-tabs-link-hover-border-color @nav-tabs-border-color @nav-tabs-link-hover-border-color @nav-tabs-link-hover-border-color;
      }
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      border-color: @nav-tabs-border-color transparent @nav-tabs-border-color @nav-tabs-border-color;
      .box-shadow(-1px 1px 1px rgba(0,0,0,.05));
    }
  }
}

// Right tabs.
.tabs-right {
  > .nav-tabs {
    float: right;
    margin-left: -1px;
    > li > a {
      border-radius: 0 @border-radius-base @border-radius-base 0;
      &:hover,
      &:focus {
        border-color: @nav-tabs-link-hover-border-color @nav-tabs-link-hover-border-color @nav-tabs-link-hover-border-color @nav-tabs-border-color;
        .box-shadow(1px 1px 1px rgba(0,0,0,.05));
      }
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      border-color: @nav-tabs-border-color @nav-tabs-border-color @nav-tabs-border-color transparent;
    }
  }
}

// Checkbox cell fix.
th.checkbox,
td.checkbox,
th.radio,
td.radio {
  display: table-cell;
}

// Views UI fixes.
.views-display-settings {
  .label {
    font-size: 100%;
    color:#666666;
  }
  .footer {
    padding:0;
    margin:4px 0 0 0;
  }
}

// Radio and checkbox in table fixes
table {
  .radio input[type="radio"],
  .checkbox input[type="checkbox"] {
    max-width: inherit;
  }
}

// Exposed filters
.form-horizontal .form-group label {
  position: relative;
  min-height: 1px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
  padding-left:  (@grid-gutter-width / 2);
  padding-right: (@grid-gutter-width / 2);
  text-align: right;

  @media (min-width: @screen-sm-min) {
    float: left;
    width: percentage((2 / @grid-columns));
  }
}

// Treat all links inside alert as .alert-link
.alert a {
  font-weight: @alert-link-font-weight;
}
.alert-success a {
  color: darken(@alert-success-text, 10%);
}
.alert-info a {
  color: darken(@alert-info-text, 10%);
}
.alert-warning a {
  color: darken(@alert-warning-text, 10%);
}
.alert-danger a {
  color: darken(@alert-danger-text, 10%);
}




/* page tabs */

.page-tabs{

	position: relative;
	display: block;
	clear: both;
	width: 100%; overflow: hidden;

	ul.nav-tabs{

		border: 0;
		margin: 0 0 15px;
    padding: 2px 0;

		li{}
		li a{

			font-size: 13px;
			padding: 6px 10px;
			margin-right: 5px;
			border-radius: 5px;
			border: 1px solid rgba(0,0,0,0.1);
			background: rgba(255,255,255,0);
			color: rgba(0,0,0,0.5);

			&:hover{
				border: 1px solid rgba(0,0,0,0.2);
				background: rgba(10,10,10,1);
				color: rgba(255,255,255,1);
			}

			&.active{
				border: 1px solid rgba(0,0,0,0.3);
				background: rgba(255,255,255,0.1);
				color: rgba(0,0,0,0.7);
			}

		}

	}

}


/* media defaults */
.media-youtube-video,
.media-vimeo-video{
  text-align: center; margin: 20px 0;
}
.col-xs-12 > .media-youtube-video,
.col-xs-12 > .media-vimeo-video{
  @media @tablet{
    margin: 5px 0;
  }
}
.media-youtube-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.media-youtube-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* isotope.js */
ul.isotope-container {
  list-style-type: none;
  padding-left: 0;
  li {
    &.isotope-grid-sizer { width: 33.33%; }
    &.isotope-gutter-sizer { width: 6%; }
    &.isotope-element {
      width: 100%;
      @media @tablet{
        width: 33.33%;
      }
    }
    &.isotope-element.my-data-value { width: 48%; }
  }
}

/* EU cookie display */
#sliding-popup {
  .popup-content {
    padding: 1em 0;
    width: 50%;
    #popup-text {
      font-weight: normal;
    }
    #popup-buttons {
      button {
        margin-top: 0.5em;
      }
    }
  }
}

.ui-widget-overlay{
  background: none;
}
.form-item-linkit-search{
  .linkit-search-element{
    margin-bottom: 0px;
  }
  .better-autocomplete{
    .group{
      margin-bottom: 0;
    }
  }
}

/* Social share links */
.field-name-ssl{ //Social icons
  margin-bottom: 24px;
  &:before{
    content: 'SHARE: ';
    color: #8f8f7c;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  .field-items{
    display: inline-block;
    vertical-align: middle;
  }
  a.ssl{
    display: inline-block;
    width: 40px;
    height: 40px;
    background-image: url('../../images/social-links.png');
    background-size: 120px 40px;
    background-repeat: no-repeat;
    margin-right: 1px;
    margin-left: 0px;
    margin-right: 10px;
    .ssl-text{
      display: none;
    }
    &.ssl-facebook{
      background-position: -80px 0;
      margin-right: 5px;
    }
    &.ssl-twitter{
      background-position: 0 0px;
      float: left;
    }
    &.ssl-linkedin{
      background-position: -40px 0px;
    }
  }
}

.view-investors-and-advisors {
  .ds-2col-fluid {
    .group-left {
      width: 100%;
      @media @tablet {
        width: 35%;
      }
    }
    .group-right {
      width: 100%;
      @media @tablet {
        width: 65%;
      }
    }
    .field.field-name-field-logo {
      margin-bottom: 0;
      @media @tablet {
        margin-bottom: 20px;
      }
      img{
        width: 100%;
      }
    }
  }
}

p {
  .img-responsive {
    display: block;
    @media @tablet {
      display: inline-block;
    }
  }
}

body.page-node-edit.node-type-investor {
  .text-format-wrapper .filter-wrapper,
  #edit-field-related-case-studies {
    display: none;
  }
}

#views-exposed-form-investors-and-advisors-page,
#views-exposed-form-case-studies-page {
  .form-type-bef-checkbox {
    label {
      /*float: left;*/
      display: inline;
    }
    .product-info-icon {
      /*float: left;*/
      display: inline;
      margin-left: 10px;
      margin-top: -2px;
    }
  }
}

.entity-card {
  .partner-image {
    max-width: 200px;
  }
}

.node-type-case-study {
  .node-case-study {
    &.view-mode-full {
      .field-name-field-pdf-attachment a {
        margin-bottom: 0 !important;
        .btn;
        .btn-default;
      }
      img.file-icon, span.file-size {
        display:none;
      }
    }
  }
}

#block-block-8 .btn {
  &::after {
    content: ' ';
    display: block;
    background: transparent url(../../images/Stroke.png) no-repeat;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 60%;
    transform: translateY(-50%);
  }
}

body.front .entity-card {
  font-family: @font-family-sans-serif;
  h3 {
    font-size: 18px;
    letter-spacing: -0.56px;
    line-height: 28px;
    font-family: @font-family-sans-serif;
    font-weight: 500;
    @media @wide {
      font-size: 28px;
      line-height: 42px;
    }
  }
  p {
    font-size: @font-size-base;
    line-height: 28px;
    font-weight: 500;
  }
}
