//
// Progress bars
// --------------------------------------------------


// Bar animations
// -------------------------

// Webkit
@-webkit-keyframes progress-bar-stripes {
  from  { background-position: 40px 0; }
  to    { background-position: 0 0; }
}

// Firefox
@-moz-keyframes progress-bar-stripes {
  from  { background-position: 40px 0; }
  to    { background-position: 0 0; }
}

// Opera
@-o-keyframes progress-bar-stripes {
  from  { background-position: 0 0; }
  to    { background-position: 40px 0; }
}

// Spec and IE10+
@keyframes progress-bar-stripes {
  from  { background-position: 40px 0; }
  to    { background-position: 0 0; }
}



// Bar itself
// -------------------------

// Outer container
.progress {
  overflow: hidden;
  height: @line-height-computed;
  margin-bottom: @line-height-computed;
  background-color: @progress-bg;
  border-radius: @border-radius-base;
  .box-shadow(inset 0 1px 2px rgba(0,0,0,.1));
}

// Bar of progress
.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: @font-size-small;
  color: @progress-bar-color;
  text-align: center;
  background-color: @progress-bar-bg;
  .box-shadow(inset 0 -1px 0 rgba(0,0,0,.15));
  .transition(width .6s ease);
}

// Striped bars
.progress-striped .progress-bar {
  #gradient > .striped(@progress-bar-bg);
  background-size: 40px 40px;
}

// Call animation for the active one
.progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
     -moz-animation: progress-bar-stripes 2s linear infinite;
      -ms-animation: progress-bar-stripes 2s linear infinite;
       -o-animation: progress-bar-stripes 2s linear infinite;
          animation: progress-bar-stripes 2s linear infinite;
}



// Variations
// -------------------------

.progress-bar-success {
  .progress-bar-variant(@progress-bar-success-bg);
}

.progress-bar-info {
  .progress-bar-variant(@progress-bar-info-bg);
}

.progress-bar-warning {
  .progress-bar-variant(@progress-bar-warning-bg);
}

.progress-bar-danger {
  .progress-bar-variant(@progress-bar-danger-bg);
}
