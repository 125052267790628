.view-latest-search {
  .view-content {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    .views-row {
      width: 100%;
      padding: 0 15px;
      @media @tablet {
        width: 50%;
      }
      @media @normal {
        width: 33.3333%;
      }
    }
  }
}