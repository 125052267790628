/* impact matrix */

.main-content-column .tooltip-list {
  display: none;
  position: absolute;
  padding: 8px;
  margin-top: -20px;
  margin-left: -20px;
  ul {
    border: none;
    margin: 0;
    padding: 0;
    li {
      display: none;
      list-style: none;
      margin: 0;
      padding: 0px !important;
      background: none !important;
      border: 0 none !important;
      width: 280px;
      color: #fff !important;
      font-weight: 700;
      &.tooltip-highlight {
        display: block;
        width: 186px;
      }
    }
  }
}

.arrow-marker {
  display: block;
  width: 30px;
  height: 30px;
  margin: auto auto;
  position: absolute;
  background-image: url('../../images/poly-down.png');
  background-repeat: no-repeat;
  background-size: 80%;
  left: 0;
  right: 0;
  bottom: -30px;
}

/* Individual and Community measure listings */
/* Applies to both individual and community blocks */
#block-views-measures-block-1,
#block-views-measures-block-2 {
  background-color: #fff;
  border: 2px solid;

  @media @tablet {
    float: left;
    width: 47%;
  }
  .view-header {
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 10px 20px;
  }
  h2 {
    .h3();
    margin-bottom: 0;
    padding: 10px;
  }
  h3 {
    font-size: @font-size-base;
    font-weight: normal;
    margin: 0;
    padding: 15px 15px 15px 30px;
    text-transform: none;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .measure-list {
    margin-bottom: 0;
    padding: 0 15px;
    li.views-row {
      border-top: 1px solid;
      list-style: none;
      margin-left: 0;
      padding: 10px 25px 10px 20px;
      position: relative;
      /*&.open .views-field-title {
          padding-left: 25px;
      }*/
      &.views-row {
        border-bottom: 0 none;
        &.views-row-first {
          border-top: 0 none;
        }
        &.views-row-last {

        }
      }
      &:hover {
        cursor: pointer;
      }
      .flag-wrapper {
        //position: absolute;
        //top: 13px;
        //right: 0px;
      }
    }
  }
  /* Save/remove measures flag */
  .flag-save-measures {
    a {
      display: block;
      .btn;
      .btn-default;
      margin-bottom: 10px;
    }
  }
}

/* Applies to just individual block */
#block-views-measures-block-1 {
  border-color: #f08522;
  margin-bottom: 20px;
  @media @tablet {
    margin-right: 10px;
  }
  .item-list {
    border-bottom: 1px solid #f08522;
    &:last-of-type {
      border-bottom: 0 none;
    }
    h3 {
      background: url('../../images/poly-right.png') no-repeat 14px 20px;
    }
    &.open {
      h3 {
        background: url('../../images/poly-down.png') no-repeat 12px 23px;
      }
    }

  }
  h2 {
    background: url('/sites/all/themes/good_finance_theme/images/person.png') 10px 12px no-repeat;
    padding-left: 55px;
    margin: 0;
    font-size: 22px;
  }
  .view-header {
    border-color: #f08522;
  }
  .outcomes-list {
    background-color: #fdf3e8;
  }
  .measure-list {
    li {
      border-color: #f08522;
      background: url('../../images/poly-right.png') no-repeat 6px 16px;
      &.open {
        background: url('../../images/poly-down.png') no-repeat 4px 19px;
      }
      &.item-highlight {
        background-color: #f9d2ac !important;

      }
    }
  }
  .tooltip-list {
    background-color: #f18b2c !important;
  }
}

/* Applies to just community block */
#block-views-measures-block-2 {
  border-color: #9868ab;
  @media @tablet {
    margin-left: 10px;
  }

  .item-list {
    border-bottom: 1px solid #696958;
    &:last-of-type {
      border-bottom: 0 none;
    }
    h3 {
      background: url('../../images/poly-right-purple.png') no-repeat 14px 20px;
    }
    &.open {
      h3 {
        background: url('../../images/poly-down-purple.png') no-repeat 12px 23px;
      }
    }
  }

  h2 {
    background: url('/sites/all/themes/good_finance_theme/images/people.png') 10px 8px no-repeat;
    padding-left: 70px;
    margin: 0;
    font-size: 22px;
  }
  .view-header {
    border-color: #9868ab;
  }
  .outcomes-list {
    background-color: #f4f0f6;
  }
  .measure-list {
    li {
      border-color: #9868ab;
      background: url('../../images/poly-right-purple.png') no-repeat left 14px;
      &.item-highlight {
        background-color: #e1c1ee !important;
      }
    }
  }
  .tooltip-list {
    background-color: #8d5aa1 !important;
  }

  .arrow-marker {
    background-image: url('../../images/poly-down-purple.png');
  }
}

.impact-matrix-section {
}

/* Outcomes area index */
.all-outcome-areas-header {
  clear: both;
  padding-top: 0px;
}

.view-impact-matrix-index {
  overflow: overlay;
  background-color: #fff;
  padding: 10px;
  h3{
    margin-bottom: 30px;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 26px;
  }
  ul {
    padding-left: 0;
    li.views-row {
      border: none;
      display: inline-block;
      float: left;
      margin-right: 4%;
      min-height: 80px;
      padding-left: 45px;
      padding-top: 0;
      padding-bottom: 0;
      width: 46%;
      &.matrix-index-93 {
        background: #fff url('../../images/outcome-area_employment.png') no-repeat left top !important;
      }
      &.matrix-index-111 {
        background: #fff url('../../images/outcome-area_housing.png') no-repeat left top !important;
      }
      &.matrix-index-120 {
        background: #fff url('../../images/outcome-area_income.png') no-repeat left top !important;
      }
      &.matrix-index-138 {
        background: #fff url('../../images/outcome-area_health.png') no-repeat left top !important;
      }
      &.matrix-index-129 {
        background: #fff url('../../images/outcome-area_wellbeing.png') no-repeat left top !important;
      }
      &.matrix-index-102 {
        background: #fff url('../../images/outcome-area_family.png') no-repeat left top !important;
      }
      &.matrix-index-74 {
        background: #fff url('../../images/outcome-area_community.png') no-repeat left top !important;
      }
      &.matrix-index-68 {
        background: #fff url('../../images/outcome-area_arts.png') no-repeat left top !important;
      }
      &.matrix-index-83 {
        background: #fff url('../../images/outcome-area_environment.png') no-repeat left top !important;
      }
      a {
        border-left: 1px solid #7c7b67;
        display: inline-block;
        padding-left: 10px;
      }
    }
  }
}

.block-views-flagged_measures-block_1-instance{
  margin-bottom: 0;
  margin-top: 20px;
}

/* Beneficiaries filter */
#block-views-beneficiaries-block,
.block-views-beneficiaries-block-instance{
  .view-header {
    padding: 0 15px;
  }
  .flag-throbber {
    display: none;
  }
  ul {
    padding: 0;
    margin-top: 20px;
    li {
      margin: 0;
      list-style: none;
      &.views-row {
        border: none;
        padding: 0;
      }
      a {
        display: block;
      }
      .item-highlight a {
        background-image: url('/sites/all/themes/good_finance_theme/images/close-red.png');
        background-position: center right 10px;
        background-repeat: no-repeat;
        background-color: #a9aeaf;
        padding: 7px 35px 7px 15px;
        color: @text-color;
      }
    }
  }
}

.saved-measure-list {
  ul {
    list-style: none;
    margin-top: 20px;
    padding-left: 0;
    li {
      margin: 0;
      list-style: none;
      padding: 10px 5px;
      &.views-row {
        padding: 0 11px;
        border: 0 none;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
        &:last-child{
          border-bottom: 0;
        }
      }
      .flag-wrapper{
        display: flex;
        flex-direction: row-reverse;
      }
      a {
        font-weight: normal;

        display: block;
        outline: none;
        color: #3f3f3f;
        &:hover,
        &:focus {
          background: none;
          text-decoration: none;
        }
        &.active-trail {
          background: #c8c8c0;
          font-weight: 700;
        }
        &.unflag-action {
          width: 30px;
          height:30px;
          margin-top: 3px;
          background-image: url('/sites/all/themes/good_finance_theme/images/close-red.png');
          background-position: top right;
          background-repeat: no-repeat;
          padding: 0 !important;
          text-indent: -900000px;
        }
      }
    }

  }
}

.view-empty {
  font-weight: 700;
  margin-top: 10px;
}

#block-views-flagged-measures-block-1 {
  .views_flag_refresh-throbber {
    display: none;
  }
}

body.logged-in {
  .view-flagged-measures {
    .im-left {
      display: none;
    }
    .view-header,
    .view-empty {
      padding: 0 15px;
    }
    .saved-measure-list {
      ul {
        margin: 0;
        padding: 15px;
      }
    }
    .more-link {
      padding: 0 15px;
    }
  }
}

.view-flagged-measures {
  .view-header,
  .view-empty {
    padding: 0 15px;
  }
  .saved-measure-list {
    ul {
      margin: 0;
      padding: 15px;
    }
  }
  .more-link {
    padding: 0 15px;
  }
  .views-table {
    .table;
  }
}
